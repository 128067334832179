import _ from "lodash";
import { Text, useTheme } from "react-native-paper";
import { Scene, sortScenes, toScene } from "../common/scene";
import React, { ReactNode } from "react";
import { DocumentReference, DocumentSnapshot, QuerySnapshot } from "@atgof-firebase/types";
import { SheetCategory, isCharacterCategory } from "../common/sheet";
import { useSheets } from "../data/useSheets";
import { View } from "react-native";
import { transformSnapshot, useSnapshot } from "../data/useSnapshot";
import { SceneStatusView, intExtDescription, setsDescription } from "../common/SceneInfo";
import { Phrase, PhraseKey } from "../common/phrases";
import { Table } from "./Table";
import { TableCellComponent, TableRowComponent, TableSection } from "./types/table";

export type ScenesTableSection = TableSection<Scene>

export type CellRenderProps<T> = {
  item: T,
  scene: Scene,
  category: SheetCategory | undefined,
  character: string | undefined
};


export type ScenesTableColumn<T> = {
  key: string;
  label: string;
  render: (props: CellRenderProps<T>) => ReactNode;
  backgroundColorForItem?: (item: T, dark: boolean) => string | undefined;
}

type Columns<T> = ScenesTableColumn<T>[];

type ColumnGroupBase = {
  key: string;
  label?: string;
}

export type SceneColumnGroup = ColumnGroupBase & {
  kind: 'scene';
  columns: Columns<Scene>
}

type CharacterColumnGroup = ColumnGroupBase & {
  kind: 'character';
  columns: Columns<string>
}

export type SheetColumnGroup = ColumnGroupBase & {
  kind: { sheetCategory: SheetCategory };
  columns: Columns<DocumentSnapshot>
}

export type ScenesTableColumnGroup = SceneColumnGroup | CharacterColumnGroup | SheetColumnGroup;

export function useScenesTableSectionForEpisode(
  episodeRef: DocumentReference | undefined,
  teams: DocumentSnapshot[] | undefined
) {
  const scenesColl = episodeRef?.collection('scenes');
  return useSnapshot(
    transformSnapshot<QuerySnapshot, ScenesTableSection[]>(
      scenesColl ? _.bind(scenesColl.onSnapshot, scenesColl) : undefined,
      ({ docs }) => ([{
        key: episodeRef?.id ?? "",
        entries: sortScenes(docs).map(doc => toScene(doc, teams))
      }])
    ),
    [episodeRef?.path, teams]
  );
}

export function sceneColumn(
  ph: (k: PhraseKey) => Phrase,
  extras?: (scene: Scene) => React.ReactNode
): ScenesTableColumn<Scene> {
  return {
    key: 'scene',
    label: ph('scene') as string,
    backgroundColorForItem: (scene, dark) =>
      dark ? undefined : scene.teamData?.backgroundColour,
    render: ({ item }) => {
      const { dark } = useTheme();
      return (
        <View>
          <SceneStatusView
            scene={item}
            excludeTimeOfDay
            fontSize={null}
            color={dark ? item.teamData?.backgroundColour : undefined}
            sceneLabelSeparator=">"
          />
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {extras ? extras(item) : null}
          </View>
        </View>
      );
    }
  };
}

export function locationColumn(
  ph: (k: PhraseKey) => Phrase
): ScenesTableColumn<Scene> {
  return {
    key: 'location',
    label: ph('location') as string,
    render: ({ item }) =>
      <React.Fragment>
        <Text style={{ fontWeight: 'bold' }} numberOfLines={1}>
          {setsDescription(item)}
        </Text>
        <View style={{ flexDirection: "row", gap: 4, alignItems: 'center' }}>
          <Text>
            {intExtDescription(item, ph).toLocaleUpperCase()}
          </Text>
          <Text>{item.comment}</Text>
        </View>
        <Text variant="bodySmall">{item.synopsis}</Text>
      </React.Fragment>
  };
}

function Cells(
  { columnGroup, scene, character, rowIndex, groupIndex, rowCount, TableCell }:
    {
      columnGroup: ScenesTableColumnGroup;
      scene: Scene;
      character: string | undefined;
      rowIndex: number;
      groupIndex: number;
      rowCount: number;
      TableCell: TableCellComponent
    }
) {
  const { dark } = useTheme();
  const { key, kind, columns } = columnGroup;
  const sheetCategory = typeof kind === 'object' ? kind.sheetCategory : undefined;
  const perScene = kind === 'scene';
  const perCharacter = kind == 'character' ||
    (!perScene && isCharacterCategory(sheetCategory));
  const item = perScene ? scene : (kind === 'character' ? character : undefined);
  const sheets = useSheets(
    sheetCategory ? scene.ref : undefined,
    typeof kind === 'object' ? [kind.sheetCategory] : [],
    perCharacter && character ? { subjectKind: 'character', subjectName: character } : undefined
  );
  const loading = !(item || sheets);
  const sheet = sheets?.length ? sheets[0] : undefined;
  const isFirstCharacter = rowIndex == 0;
  const toRender = item ?? sheet;
  return perCharacter || isFirstCharacter ?
    columns.map((column, colI) => {
      return (
        <TableCell
          key={key + " " + column.key}
          loading={loading}
          rowIndex={rowIndex}
          groupIndex={groupIndex}
          columnIndex={colI}
          rowSpan={perCharacter ? 1 : rowCount}
          backgroundColor={toRender && column.backgroundColorForItem ?
            column.backgroundColorForItem(toRender as any, dark) : undefined
          }
        >
          <View style={{
            flex: 1, height: "100%",
            alignSelf: 'flex-start',
            padding: 8
          }}>
            {
              toRender || (sheetCategory && !loading) ?
                React.createElement(column.render as any, // TODO
                  {
                    item: toRender as any, // TODO
                    scene,
                    category: sheetCategory,
                    character: perCharacter ? character : undefined
                  }) : null
            }
          </View>
        </TableCell >
      );
    }) : [];
}

function SceneRow(
  { entry, columnGroups, TableRow, TableCell }:
    {
      entry: Scene;
      columnGroups: ScenesTableColumnGroup[];
      TableRow: TableRowComponent;
      TableCell: TableCellComponent
    }) {
  const { cast } = entry;
  const rows = cast?.length ? cast : [undefined];
  return rows.map((character, rowI) =>
    <TableRow key={character ?? ""}>
      {columnGroups.map((columnGroup, groupI) =>
        <Cells
          key={columnGroup.key}
          columnGroup={columnGroup}
          scene={entry}
          character={character}
          rowIndex={rowI}
          groupIndex={groupI}
          rowCount={rows.length}
          TableCell={TableCell}
        />)}
    </TableRow>
  );
}

function columnGroupOrder({ kind }: ScenesTableColumnGroup) {
  return kind === 'scene' ? 0 :
    (kind === 'character' ? 1 :
      (isCharacterCategory(kind.sheetCategory) ? 2 : 3)
    );
}

const coreColumns = ['scene', 'location', 'character'];

export function ScenesTable({ sections, ...props }: {
  sections: ScenesTableSection[];
  columnGroups: ScenesTableColumnGroup[]
}) {
  const columnGroups = props.columnGroups
    .filter(({ columns }) => columns.length)
    .sort((a, b) => columnGroupOrder(a) - columnGroupOrder(b));
  const totalCols = coreColumns.length +
    columnGroups.map(({ columns }) => columns.length).reduce((a, b) => a + b, 0);
  return (
    <Table<Scene, ScenesTableColumnGroup>
      columnGroups={columnGroups} sections={sections}
      totalCols={totalCols}
      Row={SceneRow}
    />
  );
} 
