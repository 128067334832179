import React from "react";
import { ActivityIndicator } from "react-native";
import { useSeasons } from "../data/useSeasons";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import LabelledSelect from "./LabelledSelect";

export default function SeasonSelector(
  { includeHistorical = false, selectedSeason, setSelectedSeason }:
    {
      includeHistorical?: boolean
      selectedSeason: DocumentSnapshot | undefined;
      setSelectedSeason: (season: DocumentSnapshot | undefined) => void
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const seasons = useSeasons(includeHistorical);
  if (!seasons) return <ActivityIndicator />;
  return (
    <LabelledSelect<DocumentSnapshot>
      label={ph('season') as string}
      entries={seasons}
      entryKey={doc => doc.id} entryLabel={doc => doc.id}
      selectedEntry={selectedSeason}
      onEntryChange={setSelectedSeason}
      autoselect
    />
  );
}
