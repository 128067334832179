import * as React from 'react';
import { FontAwesome, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { NavigationContainer, Theme, useNavigation } from '@react-navigation/native';
import { createDrawerNavigator, DrawerNavigationOptions } from '@react-navigation/drawer';

import CharactersScreen from '../screens/CharactersScreen';
import EpisodesScreen from '../screens/EpisodesScreen';
import SetsScreen from '../screens/SetsScreen';
import SettingsScreen from '../screens/SettingsScreen';
import UsersScreen from '../screens/UsersScreen';

import { RootStackParamList, RootTabParamList } from '../types';

import { LanguageContext } from '../common/language';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import SheetScreen from '../screens/SheetScreen';
import { episodeLabel } from '../common/util';
import SheetSubjectDetailScreen from '../screens/SheetSubjectDetailScreen';
import EpisodeScreen from '../screens/EpisodeScreen';
import SceneScreen from '../screens/SceneScreen';
import SheetsScreen from '../screens/SheetsScreen';
import ScriptScreen from '../screens/ScriptScreen';
import FileImportsScreen from '../screens/FileImportsScreen';
import { isCharacterCategory } from '../common/sheet';
import { ProjectState } from '../data/project';
import { useProject } from "../data/useProject";
import { MembershipResults } from '../common/membership';
import MembershipScreen from '../screens/MembershipScreen';
import FileImportScreen from '../screens/FileImportScreen';
import { Phrase } from '../common/phrases';
import NewInvitationScreen from '../screens/NewInvitationScreen';
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import ScheduleScreen from '../screens/ScheduleScreen';
import LookScreen from '../screens/LookScreen';
import PropScreen from '../screens/PropScreen';
import EpisodeBreakdownScreen from '../screens/EpisodeBreakdownScreen';
import NavigationBar from '../components/NavigationBar';
import { Text } from 'react-native-paper';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import DownloadsScreen from '../screens/DownloadsScreen';
import { sceneLabel } from '../common/scene';
import { ProjectContext } from '../data/projectContext';

const linking = {
  prefixes: [Linking.createURL('/')]
};

const RootStack = createNativeStackNavigator<RootStackParamList>();

export default function Navigation(
  { invitations, theme }:
    {
      invitations: MembershipResults;
      projects: ProjectState[];
      theme?: Theme
    }
) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <NavigationContainer theme={theme} linking={__DEV__ ? linking : undefined}
        fallback={<Text>{ph('loading') as string}</Text>}
      >
        <RootStack.Navigator screenOptions={{
          header: (props) => <NavigationBar {...props} />
        }}>
          <RootStack.Group>
            <RootStack.Screen name="home" options={{ headerShown: false }}>
              {() => <DrawerNavigator invitations={invitations} />}
            </RootStack.Screen>
          </RootStack.Group>
          <RootStack.Group screenOptions={{
            presentation: 'card',
          }}>
            <RootStack.Screen name="sheet">
              {(props) => <SheetScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="look">
              {(props) => <LookScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="prop">
              {(props) => <PropScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="episodeBreakdown">
              {(props) => <EpisodeBreakdownScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="sheetSubjectDetail"
              options={({ route }) => ({ title: route.params.subjectName })}
            >
              {(props) => <SheetSubjectDetailScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="membership"
              options={({ route }) => ({ title: route.params.displayName })}
            >
              {(props) => <MembershipScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="newInvitation"
              options={() => ({ title: ph('new-invitation') as string })}
            >
              {(props) => <NewInvitationScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="episode"
              options={({ route }) => ({ title: episodeLabel(ph, route.params.episodePath) })}
            >
              {(props) => <EpisodeScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="scene"
              options={({ route }) => ({ title: ph('scene') + ' ' + sceneLabel(route.params.scenePath) })}
            >
              {(props) => <SceneScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="script"
              options={() => ({ title: ph('script') as string })}
            >
              {(props) => <ScriptScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="fileImports"
              options={() => ({ title: ph('import-files') as string })}
            >
              {(props) => <FileImportsScreen {...props} />}
            </RootStack.Screen>
            <RootStack.Screen name="fileImport"
              options={() => ({ title: ph('import-file') as string })}
            >
              {(props) => <FileImportScreen {...props} />}
            </RootStack.Screen>
          </RootStack.Group>
        </RootStack.Navigator>
      </NavigationContainer>
    </GestureHandlerRootView>
  );
}

const Drawer = createDrawerNavigator<RootTabParamList>();

function DrawerNavigator({ invitations }: { invitations: MembershipResults }) {
  const { ph } = React.useContext(LanguageContext);

  const project = useProject();
  const { categories, adminCategories } = React.useContext(ProjectContext);
  const isPostProductionUser = categories.includes('postProduction');
  const isAdmin = adminCategories.length > 0;
  const initialRouteName =
    project ? (isPostProductionUser ? 'schedule' : 'sheets') : 'settings';
  const navigation = useNavigation();

  React.useEffect(() => { navigation.navigate(initialRouteName as any); }, [initialRouteName])

  function screenOptions(ph: (k: string) => Phrase, name: string, iconAs: any, iconName: string)
    : DrawerNavigationOptions {
    return {
      title: ph(name).toString(),
      drawerIcon: ({ color, size }: { focused: boolean, color: string, size: number }) =>
        React.createElement(iconAs, { name: iconName, color: color, size: size })
    };
  }

  const screens = [
    isAdmin &&
    <Drawer.Screen key="users" name="users" options={screenOptions(ph, 'users', FontAwesome, 'users')}>
      {(props) =>
        <UsersScreen {...props} categories={categories} />}
    </Drawer.Screen>,

    project &&
    <Drawer.Screen key="sheets" name="sheets"
      options={screenOptions(
        ph, isAdmin || categories.includes('postProduction') ?
        (Platform.OS === 'web' ? 'sheets' : 'recent-sheets') : (Platform.OS === 'web' ? 'my-sheets' : 'my-recent-sheets'),
        MaterialCommunityIcons, 'file-document-multiple-outline')}
    >
      {(props) => <SheetsScreen {...props} />}
    </Drawer.Screen>,

    categories.indexOf('arts') !== -1 &&
    <Drawer.Screen key="sets" name="sets"
      options={screenOptions(ph, 'sets', MaterialIcons, 'house-siding')}
    >
      {(props) => <SetsScreen {...props} />}
    </Drawer.Screen>,

    ([...categories].find(isCharacterCategory) || categories.includes('pa')) &&
    <Drawer.Screen key="characters" name="characters"
      options={screenOptions(ph, 'characters', FontAwesome5, 'user-astronaut')}
    >
      {(props) => <CharactersScreen {...props} />}
    </Drawer.Screen>,

    project &&
    <Drawer.Screen key="episodes" name="episodes"
      options={screenOptions(ph, 'episodes', FontAwesome, 'file-movie-o')}>
      {(props) => <EpisodesScreen {...props} />}
    </Drawer.Screen>,

    project &&
    <Drawer.Screen key="schedule" name="schedule"
      options={screenOptions(ph, 'schedule', MaterialIcons, 'access-time')}>
      {(props) => <ScheduleScreen {...props} />}
    </Drawer.Screen>,

    <Drawer.Screen key="downloads" name="downloads"
      options={screenOptions(ph, 'downloads', MaterialIcons, 'file-download-done')}>
      {(props) => <DownloadsScreen {...props} />}
    </Drawer.Screen>,

    <Drawer.Screen key="settings" name="settings"
      options={screenOptions(ph, 'settings', MaterialIcons, 'settings')}
    >
      {(props) => <SettingsScreen invitations={invitations} {...props} />}
    </Drawer.Screen>
  ].filter(x => x);

  return (
    <Drawer.Navigator initialRouteName={initialRouteName}
      screenOptions={{ header: (props) => <NavigationBar {...props} /> }}>
      {screens}
    </Drawer.Navigator>
  );
}
