import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/firebase";
import FieldsRow from "./fields/FieldsRow";
import { Field } from "../common/helpers/fields";
import { View } from "react-native";
import React from "react";
import { updateLatestSlate } from "../data/team";
import { IconButton } from "react-native-paper";
import * as PA from "../common/sheet/pa";

function navigateToScript(navigation: any, sheet: DocumentSnapshot, take: DocumentReference) {
  navigation.navigate(
    'script', { sheetPath: sheet.ref.path, currentTakePath: take.path }
  );
}

export function getRows(
  teamRef?: DocumentReference | undefined, includeTakes: boolean = true
): Field[][] {
  return PA.getRows(
    includeTakes,
    v => teamRef?.update({ "pa:cameraCardNumber": v }),
    v => teamRef?.update({ "pa:soundCardNumber": v }),
    (newValue, oldValue, doc) => updateLatestSlate(teamRef, newValue, oldValue, doc?.id),
    navigateToScript,
    (navigation: any, doc: DocumentSnapshot, v: any) =>
      <IconButton style={{ alignSelf: "flex-end" }} icon="script-text"
        onPress={() => navigateToScript(navigation, doc, v.ref)} />
  );
}
export default function PASheet(
  { sheet, scene }: { sheet: DocumentSnapshot; scene: DocumentSnapshot }
) {
  const [rows, setRows] = React.useState(getRows());
  const teamRef: DocumentReference | undefined = scene.get('team');
  React.useEffect(() => setRows(getRows(teamRef)), [teamRef?.path]);
  return (
    <View>
      {rows.map((fields, i) =>
        <FieldsRow key={i} doc={sheet} category="pa" fields={fields} />)}
    </View>
  );
}
