import React from "react";
import { Picker } from "@react-native-picker/picker";
import { LanguageContext } from "../common/language";
import { useTheme } from "react-native-paper";

export type SelectConfigProps = {
  placeholder?: string;
  autoselect?: boolean;
  highlightUnselected?: boolean;
  includeNone?: boolean;
  resetSelectionIfNotPresent?: boolean;
}

export type SelectProps<T> = {
  entries: T[] | undefined;
  entryKey: (entry: T) => string;
  entryLabel: (entry: T) => string;
  onEntryChange: (entry: T | undefined) => void
  selectedEntry?: T | undefined;
} & SelectConfigProps

export default function Select<T>(
  {
    entries, entryKey, entryLabel, selectedEntry, placeholder,
    onEntryChange, autoselect, highlightUnselected = true, includeNone,
    resetSelectionIfNotPresent = true,
    ...props
  }:
    SelectProps<T>
) {
  const { colors } = useTheme();
  const { ph } = React.useContext(LanguageContext);
  React.useEffect(() => {
    if (resetSelectionIfNotPresent && entries && selectedEntry &&
      !entries.find(entry => entryKey(entry) === entryKey(selectedEntry))) {
      onEntryChange(undefined);
    }
  }, [entries, entryKey, selectedEntry, onEntryChange, resetSelectionIfNotPresent]);
  React.useEffect(() => {
    if (autoselect && !selectedEntry && entries && entries.length === 1) onEntryChange(entries[0]);
  }, [autoselect, selectedEntry, entries]);
  const placeholderText = entries ?
    (includeNone ? `( ${ph('none')} )` : (placeholder || ph('choose...') as string)) :
    ph('loading') as string;
  const selectedValue = (selectedEntry && entryKey(selectedEntry)) || '';
  const noSelection = selectedValue === '';
  const highlight = highlightUnselected && entries && noSelection;
  const color = highlight ? colors.primary : colors.onBackground;
  return (
    <Picker placeholder={placeholderText}
      itemStyle={{
        color
      }}
      style={{
        minWidth: 200,
        borderBottomWidth: 1,
        borderColor: colors.outline,
        backgroundColor: highlight ? colors.primaryContainer : colors.background,
        color
      }}
      selectedValue={selectedValue}
      onValueChange={(k) => onEntryChange(entries?.find(entry => entryKey(entry) === k))}
      {...props}
    >
      {[
        ...(noSelection || includeNone ?
          [<Picker.Item key="_placeholder" value="" label={placeholderText} />] : []),
        ...(entries?.map(entry => {
          const key = entryKey(entry);
          return <Picker.Item key={key} value={key || ''} label={entryLabel(entry)} />;
        }) || [])]}
    </Picker>
  );
}
