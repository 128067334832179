import { CollectionReference, DocumentReference, DocumentSnapshot, Query } from "@atgof-firebase/types";
import * as sheetSubj from "./sheet-subject";
import { OnNext, onQuerySnapshot } from "./onSnapshot";
import { notDeleted } from "./general";
import { SubjectSpec } from "./subject";
import { PhraseKey } from "./phrases";
import { Field } from "./helpers/fields";

export const SHEET_CHARACTER_CATEGORIES = ['makeUp', 'wardrobe'] as const
const CHARACTER_CATEGORIES = ['cast', ...SHEET_CHARACTER_CATEGORIES] as const;

export const SHEET_CATEGORIES = ['arts', ...SHEET_CHARACTER_CATEGORIES, 'pa'] as const;
export type SheetCategory = typeof SHEET_CATEGORIES[number]

export const sheetCategorySpecs: Record<SheetCategory, { newSheetPh: PhraseKey; newLookPh?: PhraseKey }> = {
  arts: { newSheetPh: 'new-arts-sheet', newLookPh: 'new-arts-look' },
  makeUp: { newSheetPh: 'new-makeUp-sheet', newLookPh: 'new-makeUp-look' },
  wardrobe: { newSheetPh: 'new-wardrobe-sheet', newLookPh: 'new-wardrobe-look' },
  pa: { newSheetPh: 'new-pa-sheet' },
};

export type SheetCharacterCategory = typeof SHEET_CHARACTER_CATEGORIES[number];

export function isCharacterCategory(category: string | undefined) {
  return category ? CHARACTER_CATEGORIES.includes(category as any) : false;
}

export const SUBJECT_KINDS = sheetSubj.SUBJECT_KINDS;

export type SheetSubjectKind = sheetSubj.SheetSubjectKind;

export const FIELD_SPECS: { [category: string]: { [section: string]: Field[] } } = {
  makeUp: {
    'makeUp': [
      { key: "makeup-notes", kind: 'paragraph', tableColumn: "notes" },
      { key: "primer", kind: 'paragraph' },
      { key: "base", kind: 'paragraph' },
      { key: "concealer", kind: 'paragraph' },
      { key: "blusher", kind: 'paragraph' },
      { key: "powder", kind: 'paragraph' },
      { key: "eyeshadow", kind: 'paragraph' },
      { key: "eyeliner-top", kind: 'paragraph' },
      { key: "eyeliner-bottom", kind: 'paragraph' },
      { key: "mascara", kind: 'paragraph' },
      { key: "eyebrows", kind: 'paragraph' },
      { key: "lips", kind: 'paragraph' },
      { key: "nails", kind: 'paragraph' },
    ],
    'hair': [
      { key: "hair-notes", kind: 'paragraph', tableColumn: "notes" },
      { key: "hair-products", multi: {} },
      { key: "finishing-product", kind: 'paragraph' },
      { key: "hair-accessories", multi: {} },
    ],
  },
  wardrobe: {
    'wardrobe': [
      { key: "tops", multi: {} },
      { key: "bottoms", multi: {} },
      { key: "footwear", multi: {} },
      { key: "outerwear", multi: {} },
      { key: "accessories", multi: {} },
      { key: "notes", kind: 'paragraph', tableColumn: "notes" },
    ],
  },
  arts: {
    'arts': [
      { key: "action-props", multi: {}, tableColumn: "action-props" },
      { key: "dressing-props", multi: {}, tableColumn: "dressing-props" },
      { key: "food", multi: {} },
      { key: "vehicles", multi: {} },
      { key: "animals", multi: {} },
      { key: "graphics", multi: {}, tableColumn: "graphics" },
      { key: "director-notes", kind: 'paragraph', tableColumn: "director-notes" },
      { key: "notes", kind: 'paragraph', tableColumn: "notes" },
    ],
  },
};

export function sheetColour(sheet: DocumentSnapshot | undefined) {
  const sound = sheet?.get('pa:sound');
  const k = sound && 'key' in sound && sound.key;
  if (k === "sync") return '#0891b2';
  if (k === "mute") return '#db2777';
  if (k === "wildtrack") return '#059669';
  if (k === "guidetrack") return '#ea580c';
  return "#777777";
}

export function sheetsCollection(project: DocumentReference) {
  return project.collection('sheets');
}

export function onSheets(
  project: DocumentReference | undefined,
  sceneRefs: DocumentReference[] | undefined,
  categories: readonly SheetCategory[],
  subject: SubjectSpec | undefined,
  onNext: OnNext<DocumentSnapshot[] | undefined>
) {
  const q = project && sceneRefs && categories.length &&
    notDeleted(sheetsCollection(project))
      .where('category', 'in', categories).where('scene', 'in', sceneRefs);
  return onQuerySnapshot(
    q && subject ? q.where(subject.subjectKind, '==', subject.subjectName) : q,
    ({ docs }) => docs,
    onNext);
}

export function onTakes(
  sheetRef: DocumentReference | undefined,
  onlyPreferredTakes: boolean,
  onNext: (next: DocumentSnapshot[] | undefined) => void
) {
  let q: Query | undefined;
  if (sheetRef) {
    q = notDeleted(sheetRef.collection('takes'));
    if (onlyPreferredTakes) q = q?.where('preferred', '==', true);
    q = q?.orderBy('index', 'asc');
  }
  return onQuerySnapshot(q, ({ docs }) => docs, onNext);
}

export type ImageSpec = { id: string; width?: number; height?: number };

const SIZE_SUFFIXES = [undefined, 'sm', 'md'] as const;

export type ImageSizeSuffix = typeof SIZE_SUFFIXES[number];

export function imagesCollection(sheetRef: DocumentReference) {
  return sheetRef.collection("images");
}

export function imagesQuery(imagesColl: CollectionReference) {
  return notDeleted(imagesColl.orderBy("createdAt", "desc"));
}

export function withSizeSuffix(
  imagePath: string | undefined,
  sizeSuffix: ImageSizeSuffix | undefined
) {
  if (!imagePath) return;
  return imagePath + (sizeSuffix ? `_${sizeSuffix}` : '');
}
