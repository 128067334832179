import { useNavigation } from "@react-navigation/native";
import React, { ReactNode } from "react";
import { ActivityIndicator, ScrollView, TouchableOpacity, View } from "react-native";
import { Button, IconButton, Menu, Text } from "react-native-paper";
import { PhraseFunction, PhraseKey } from "../../common/phrases";
import { BackendContext, usePendingWrites } from "../../data/backend";
import { ProjectsContext } from "../../data/projectsContext";
import { LanguageContext } from "../../common/language";
import ErrorBoundary from "../ErrorBoundary";
import ErrorStrip from "../ErrorStrip";
import LoadingView from "../LoadingView";
import NewSheetButton, { NewSheetContext } from "../NewSheetButton";
import OpenDrawerButton from "../OpenDrawerButton";
import ProjectChooser from "../ProjectChooser";
import SoftwareUpdatePrompt from "../SoftwareUpdatePrompt";
import { ProgressEntry } from "../ProgressEntry";

function FileProgressEntry(
  { phraseK, count, isActive }: { phraseK: PhraseKey; count: number; isActive: boolean }
) {
  const { ph } = React.useContext(LanguageContext);
  if (!count) return null;
  return (
    <ProgressEntry isActive={isActive}>
      <View style={{ flexDirection: 'row' }}>
        <Text>{ph(phraseK) as string} </Text>
        <Text style={{ fontWeight: 'bold' }}>{count} </Text>
        <Text>{(ph('count-file') as PhraseFunction)({ count }) + (isActive ? '…' : '')}</Text>
      </View>
    </ProgressEntry>
  );
}

function ProgressIndicator() {
  const { ph } = React.useContext(LanguageContext);
  const backend = React.useContext(BackendContext);
  const { uploads, downloads } = backend;
  const { hasPendingWrites, isInternetReachable } = usePendingWrites();
  const failedUploads = uploads.filter(status => status.error);
  const activeUploadCount = uploads.length - failedUploads.length;
  const downloadCount = Object.keys(downloads).length;
  const active = hasPendingWrites || activeUploadCount > 0 || downloadCount > 0;
  const [menuVisible, setMenuVisible] = React.useState(false);
  function openMenu() { setMenuVisible(true); }
  function closeMenu() { setMenuVisible(false); }
  if (!(active || failedUploads.length)) return null;
  const noInternet = isInternetReachable === false;
  const isActive = !noInternet;
  return (
    <Menu
      visible={menuVisible} style={{ width: "50%" }}
      onDismiss={closeMenu}
      anchor={
        noInternet ?
          <IconButton onPress={openMenu} icon="wifi-alert" /> :
          (
            !active && failedUploads.length ?
              <IconButton onPress={openMenu} icon="alert" /> :
              <TouchableOpacity onPress={openMenu}><ActivityIndicator /></TouchableOpacity>
          )
      }
      anchorPosition="bottom"
    >
      <View style={{ marginLeft: 8 }}>
        <Text variant="titleMedium">
          {ph(noInternet ? 'no-internet-connection' : 'synchronising') as string}
        </Text>
        <View style={{ gap: 8, marginTop: 8 }}>
          <ProgressEntry isActive={isActive}>
            {hasPendingWrites &&
              <Text>
                {ph(noInternet ? 'waiting-to-synchronise' : 'synchronising-your-changes') as string}
              </Text>
            }
          </ProgressEntry>
          <FileProgressEntry isActive={isActive}
            phraseK={noInternet ? 'waiting-to-upload' : 'uploading'} count={activeUploadCount}
          />
          <FileProgressEntry isActive={isActive}
            phraseK={noInternet ? 'waiting-to-download' : 'downloading'} count={downloadCount} />
          <FileProgressEntry isActive={false} phraseK="failed-to-upload" count={failedUploads.length} />
        </View>
      </View>
    </Menu>
  );
}

type EditStateListener<T> = (editState: T | undefined) => void

export type Editor<T> =
  {
    onStateChange: EditStateListener<T>;
    registerListener: (listener: EditStateListener<T> | undefined) => void;
    finishEditingPhrase: string | undefined;
    defaultMode: T
  } | undefined;

export function useEditor<T>(defaultMode: T | null, finishEditingPhrase?: string) {
  const [editingMode, setEditingMode_] = React.useState<T>();
  const listenerRef = React.useRef<EditStateListener<T>>();
  const registerListener = React.useCallback(
    (listener: EditStateListener<T> | undefined) => {
      listenerRef.current = listener;
      return () => {
        listenerRef.current = undefined;
      };
    },
    [listenerRef]
  );
  const editor = React.useMemo<Editor<T> | null>(
    () => {
      if (!defaultMode) return null;
      return {
        onStateChange: setEditingMode_,
        registerListener,
        finishEditingPhrase,
        defaultMode
      };
    },
    [defaultMode, setEditingMode_, registerListener, finishEditingPhrase]);
  const setEditingMode = React.useCallback(
    (newEditingMode: T | undefined) => {
      setEditingMode_(newEditingMode);
      if (listenerRef.current) listenerRef.current(newEditingMode);
    },
    [setEditingMode_]
  );
  return { editingMode, setEditingMode, editor };
}

function Header<E>({
  isLoading, editor, deleteItem, addItem, onDone, isDoing, onCancel, newSheetContext, children
}: {
  isLoading: boolean | undefined;
  editor?: Editor<E> | null;
  deleteItem: (() => void) | undefined;
  addItem: (() => void) | undefined;
  onDone: (() => void) | undefined;
  isDoing: boolean | undefined;
  onCancel: (() => void) | undefined;
  newSheetContext: NewSheetContext | undefined;
  children: ReactNode
}) {
  const { ph } = React.useContext(LanguageContext);
  const [editingMode, setEditingMode] = React.useState<E>();
  const onStateChange = React.useRef<EditStateListener<E>>();
  React.useEffect(
    () => {
      onStateChange.current = editor?.onStateChange;
      if (editor?.registerListener) {
        editor.registerListener(setEditingMode);
        return () => editor.registerListener(undefined);
      }
    },
    [editor]
  );
  const toggleEditing = React.useCallback(
    () => {
      const newEditingMode = editingMode === undefined ? editor?.defaultMode : undefined;
      setEditingMode(newEditingMode);
      if (onStateChange.current) onStateChange.current(newEditingMode);
    },
    [setEditingMode, editingMode, editor?.defaultMode]
  );
  return (
    <View style={{ flexDirection: 'row', marginRight: 8, alignItems: 'center' }}>
      <ProgressIndicator />
      {!onCancel && <NewSheetButton {...newSheetContext} />}
      {!isLoading && children}
      {
        !isLoading && deleteItem &&
        <IconButton icon="delete-outline" onPress={deleteItem} />
      }
      {
        !isLoading && addItem &&
        <IconButton icon="plus" onPress={addItem} />
      }
      {
        editor &&
        <Button onPress={toggleEditing}>
          {(editingMode ? ph(editor.finishEditingPhrase || 'cancel') : ph('edit')) as string}
        </Button>
      }
      {
        !isLoading && onCancel &&
        <Button
          disabled={onDone === undefined} loading={isDoing}
          onPress={onDone}>
          {ph('done') as string}
        </Button>
      }
    </View>
  );
}

export type NavPageProps<E> = {
  children?: ReactNode;
  title?: string;
  isLoading?: boolean;
  editor?: Editor<E> | null;
  deleteItem?: () => void;
  addItem?: () => void;
  onDone?: () => void;
  isDoing?: boolean;
  onCancel?: () => void;
  headerRight?: ReactNode;
  headerStrip?: ReactNode;
  fullScreen?: boolean;
  noMargin?: boolean;
  newSheetContext?: NewSheetContext;
}

export default function NavPage<E>(
  {
    children, title, isLoading, editor,
    deleteItem, addItem, onDone, isDoing, onCancel,
    headerRight, headerStrip, fullScreen, noMargin, newSheetContext
  }
    : NavPageProps<E>
) {
  const navigation = useNavigation();
  const { ph } = React.useContext(LanguageContext);
  const { projects } = React.useContext(ProjectsContext);
  React.useLayoutEffect(() => {
    navigation.setOptions({
      ...(title ? { title: title } : {}),
      ...(
        onCancel ? {
          headerLeft: () =>
            <Button onPress={onCancel}>
              {ph('cancel') as string}
            </Button>
        } :
          (
            projects?.length > 1 && navigation.getParent() ?
              {
                headerLeft: () =>
                  <React.Fragment>
                    <OpenDrawerButton navigation={navigation} />
                    <ProjectChooser />
                  </React.Fragment>
              } :
              {}
          )
      ),
      headerBackVisible: !onCancel,
      headerRight: () =>
        <Header
          isLoading={isLoading}
          editor={editor}
          deleteItem={deleteItem}
          addItem={addItem}
          onDone={onDone}
          isDoing={isDoing}
          onCancel={onCancel}
          newSheetContext={newSheetContext}

        >
          {headerRight}
        </Header>,
    });
  }, [
    navigation, title, onCancel, projects?.length, deleteItem, addItem, onDone,
    editor, headerRight, isLoading, newSheetContext, isDoing
  ]);
  return (
    isLoading ?
      <LoadingView /> :
      <View style={{
        flex: 1,
        gap: 10,
        ...(noMargin ? {} : { paddingLeft: 16, paddingRight: 16 })
      }}>
        <ErrorBoundary><SoftwareUpdatePrompt /></ErrorBoundary>
        <ErrorBoundary>{headerStrip || <ErrorStrip />}</ErrorBoundary>
        {
          fullScreen ? children :
            <ScrollView><ErrorBoundary>{children}</ErrorBoundary></ScrollView>
        }
      </View>
  );
}
