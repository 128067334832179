import React from "react";
import { Style } from "@react-pdf/types";

type CoreComponentProps = React.PropsWithChildren<{
  style?: Style | Style[] | undefined
}>
export type CoreComponentClass =
  React.ComponentClass<CoreComponentProps> | React.FunctionComponent<CoreComponentProps>

export const CoreComponentsContext = React.createContext({
  View: (undefined as unknown) as CoreComponentClass,
  RawText: (undefined as unknown) as CoreComponentClass,
  Text: (undefined as unknown) as CoreComponentClass,
  colors: {
    fixture: ""
  }
});
