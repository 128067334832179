import { DocumentSnapshot, Timestamp } from "@atgof-firebase/firebase";
import React from "react";
import NavPage from "../components/pages/NavPage";
import { useProject } from "../data/useProject";
import { RootTabScreenProps } from "../types";
import { LanguageContext } from "../common/language";
import { formatDate } from "../common/util";
import { Platform, View } from "react-native";
import { useReactToPrint } from "react-to-print";
import ErrorBoundary from "../components/ErrorBoundary";
import dayjs from "dayjs";
import { DateRangePicker } from "../components/DateRangePicker";
import { Checkbox, IconButton, ProgressBar } from "react-native-paper";
import { useTeams } from "../data/team";
import FixtureLabel from "../common/FixtureLabel";
import { BreakdownTable, BreakdownTableSection } from "../components/BreakdownTable";
import { useStoredPref } from "../data/useStoredPref";
import { onDaysWithScenes } from "../data/day";
import { ProjectContext } from "../data/projectContext";

function TeamsSelector({
  availableTeams, selectedTeamPaths, setSelectedTeamPaths
}: {
  availableTeams: DocumentSnapshot[] | undefined;
  selectedTeamPaths: string[] | null;
  setSelectedTeamPaths: (update: (teams: string[] | null) => string[]) => void
}) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <View style={{ flexDirection: "row" }}>
      <FixtureLabel text={ph('teams') as string} />
      {availableTeams?.map(doc =>
        <Checkbox.Item
          key={doc.id}
          label={doc.id}
          status={selectedTeamPaths?.find(path => path === doc.ref.path) ? 'checked' : 'unchecked'}
          onPress={() => setSelectedTeamPaths(paths => {
            const path = doc.ref.path;
            const idx = paths?.indexOf(path) ?? -1;
            return idx == -1 ?
              [...(paths ?? []), path] :
              [...(paths?.slice(0, idx) ?? []), ...(paths?.slice(idx + 1) ?? [])];
          })}
        />
      )}
    </View>
  );
}

async function print(printWindow: HTMLIFrameElement) {
  const printContent = printWindow.contentDocument || printWindow.contentWindow?.document;
  for (const elt of printContent?.getElementsByTagName('div') ?? []) {
    if (elt.style.overflow === "scroll") elt.style.removeProperty('overflow');
  }
  printWindow.contentWindow?.print();
}

export default function ScheduleScreen({ }: RootTabScreenProps<'schedule'>) {
  const project = useProject();
  const { categories } = React.useContext(ProjectContext);
  const isPostProduction = categories.includes('postProduction');
  const [days, setDays] = React.useState<DocumentSnapshot[]>();
  const availableTeams = useTeams();
  const availableTeamPaths = React.useMemo(() => availableTeams?.map(doc => doc.ref.path), [availableTeams]);
  const [teamPaths, setTeamPaths] = useStoredPref<string[]>("ScheduleScreen.teams", []);
  const teams = React.useMemo(
    () => availableTeams ?
      teamPaths?.map(path => availableTeams.find(doc => doc.ref.path === path))
        .filter(x => x) as DocumentSnapshot[] | undefined :
      undefined,
    [availableTeams, teamPaths]);
  React.useEffect(() => {
    if (availableTeamPaths && availableTeamPaths.length == 1) setTeamPaths([availableTeamPaths[0]]);
    else setTeamPaths(ts => ts?.filter(team => availableTeamPaths?.includes(team)) ?? null);
  }, [availableTeamPaths?.join('\n')]);
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    print
  });
  const defaultStartDay = isPostProduction ? dayjs().subtract(7, 'day') : dayjs();
  const [startDay, setStartDay] = React.useState(defaultStartDay);
  const [endDay, setEndDay] = React.useState(defaultStartDay.add(7, 'day'));
  React.useEffect(() => {
    setDays(undefined);
    if (!(startDay.isValid() && endDay.isValid())) {
      setDays([]);
      return;
    }
    const startT = Timestamp.fromMillis(startDay.toDate().getTime() - 3600000); // TODO This is probably a timezone workaround, and shouldn't be required
    return project?.collection('days')
      .where('filmingDate', '>=', startT)
      .where('filmingDate', '<=', Timestamp.fromMillis(endDay.toDate().getTime()))
      .orderBy('filmingDate', 'asc')
      .onSnapshot(snapshot => setDays(snapshot.docs))
  }, [project, startDay, endDay]);
  const [sections, setSections] = React.useState<BreakdownTableSection[]>();
  React.useEffect(
    () => onDaysWithScenes(
      days, teams,
      daysWithScenes => setSections(
        daysWithScenes?.map(({ day, scenes }) => ({
          key: day.id,
          label: formatDate(new Date(day.id), true) +
            (
              availableTeams && availableTeams.length > 1 ?
                (' – ' + (teams?.map(team => team.id).join('/') ?? '')) : ''
            ),
          entries: scenes
        }))
      )
    ),
    [project, days, teams, categories, availableTeams]
  );
  return (
    <NavPage
      headerRight={
        Platform.OS === 'web' &&
        <IconButton icon="printer" onPress={handlePrint} />
      }
      fullScreen={Platform.OS === 'web'}
    >
      <DateRangePicker
        startDay={startDay} setStartDay={setStartDay} endDay={endDay} setEndDay={setEndDay} />
      <ErrorBoundary>
        <View style={{ marginTop: 8, gap: 8, flex: 1 }}>
          {availableTeams?.length == 1 ?
            null :
            <TeamsSelector
              availableTeams={availableTeams}
              selectedTeamPaths={teamPaths} setSelectedTeamPaths={setTeamPaths} />}
          {sections ?
            <View ref={componentRef} style={{
              flex: 1
            }}>
              <BreakdownTable sections={sections} />
            </View> :
            teams?.length ? <ProgressBar indeterminate /> : null}
        </View>
      </ErrorBoundary>
    </NavPage>
  );
}
