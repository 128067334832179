import * as React from 'react';
import {
  DocumentSnapshot, FirestoreError, User, UserCredential,
  initializeApp, initializeFirestore, getAuth, GoogleAuthProvider,
  getStorage, Firestore
} from '@atgof-firebase/firebase';
import * as WebBrowser from 'expo-web-browser';
import { default as NetInfo } from '@react-native-community/netinfo';
import useCachedResources from './hooks/useCachedResources';
import Navigation from './navigation';
import { onUserSnapshot } from './data/user';
import { MembershipResults } from './common/membership';
import LoadingScreen from './components/LoadingScreen';
import ErrorScreen from './components/ErrorScreen';
import { ProjectState, projectStateFor } from './data/project';
import { BackendContext, onBackendConnected, useBackend } from './data/backend';
import ErrorBoundary from './components/ErrorBoundary';
import * as Phrases from './common/phrases';
import { LanguageContext } from './common/language';
import dayjs from 'dayjs';
import {
  Platform, SafeAreaView, Text as RawText, View, useColorScheme
} from 'react-native';
import { SigninStatus, SigninToken } from './signin';
import { retrieveItem, storeItem } from './data/prefs';
import {
  adaptNavigationTheme, MD3DarkTheme, MD3LightTheme, PaperProvider, Text,
  useTheme
} from 'react-native-paper';
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
  Theme,
} from '@react-navigation/native';
import * as SplashScreen from 'expo-splash-screen';
import { useFonts } from 'expo-font';
import { RobotoCondensed_400Regular } from '@expo-google-fonts/roboto-condensed';
import { enableScreens } from 'react-native-screens';
import LoginScreen from './screens/LoginScreen';
import { configureFirebase, shouldUsePersistence } from './config';
import { appConfig, functionsUri, uploadUri } from './config/firebase';
import { StorageManagerImpl } from './data/backend/storage-manager-impl';
import { UserContext } from './data/userContext';
import { ProjectContext } from './data/projectContext';
import { ProjectsContext } from './data/projectsContext';
import { CoreComponentClass, CoreComponentsContext } from './common/core-components';

enableScreens();

SplashScreen.preventAutoHideAsync();

const app = initializeApp(appConfig);
const storageManager = new StorageManagerImpl(uploadUri);

const dbPromise = initializeFirestore(app, shouldUsePersistence);
const auth = getAuth();

configureFirebase(auth, dbPromise, getStorage());

WebBrowser.maybeCompleteAuthSession();

const kCurrentProjectId = 'currentProjectId';

function App_({ theme }: { theme: Theme }) {
  const isLoadingComplete = useCachedResources();

  const [db, setDb] = React.useState<Firestore>();
  React.useEffect(
    () => { dbPromise.then(setDb) },
    [dbPromise]
  );

  const { language, setLanguage } = React.useContext(LanguageContext);

  const signinState = React.useState({} as SigninStatus);
  const [_, setSigninStatus] = signinState;
  const [isConnected, setIsConnected] = React.useState(false);
  const [user, setUser] = React.useState(null as DocumentSnapshot | null);
  const [userUnsubscribe, setUserUnsubscribe] = React.useState(null as Function | null);
  const [invitations, setInvitations] = React.useState(null as MembershipResults);
  const [memberships, setMemberships] = React.useState(null as MembershipResults);
  const [projects, setProjects] = React.useState<ProjectState[]>([]);
  const [currentProject, setCurrentProject] = React.useState(projectStateFor(undefined));
  const [retrievedCurrentProjectId, setRetrievedCurrentProjectId] = React.useState<string | null>(null);

  React.useEffect(() => setProjects(
    !memberships || 'error' in memberships || memberships.snapshot.empty ?
      [] :
      memberships.snapshot.docs.map(projectStateFor)
  ), [memberships]);

  React.useEffect(() => { retrieveItem(kCurrentProjectId).then(setRetrievedCurrentProjectId) }, []);
  React.useEffect(() => {
    const projectId = currentProject.project?.id;
    if (projectId) storeItem(kCurrentProjectId, projectId); // TODO .catch
  }, [currentProject]);

  React.useEffect(
    () => setCurrentProject(
      cur => (cur.project?.id && projects.find(candidate => candidate.project?.id === cur.project?.id)) ||
        (retrievedCurrentProjectId && projects.find(candidate => candidate.project?.id === retrievedCurrentProjectId)) ||
        (projects.length ? projects[0] : projectStateFor(undefined))
    ),
    [retrievedCurrentProjectId, projects]);

  function ph(key: string): Phrases.Phrase {
    return Phrases.ph(language, key);
  }

  function setUserLanguage(language: string) {
    setLanguage(language);
    user?.ref.set({ language }, { merge: true });
  }

  async function logOut() {
    if (userUnsubscribe) userUnsubscribe();
    setUser(null);
    setSigninStatus({});
    await auth.signOut();
  }

  function handleUser(user: User | null) {
    if (!db) return;
    if (!user) {
      setSigninStatus({});
      setUser(null);
      return;
    }
    onUserSnapshot(db, user, language, (u) => {
      setLanguage(u.get('language') || language);
      setSigninStatus({});
      setUser(u);
    }).then(setUserUnsubscribe).catch(error => setSigninStatus({ error }));
  }

  function firebaseSignIn(signinToken: SigninToken) {
    const { provider, value } = signinToken;
    function handleUserCredential(userCredential: UserCredential) {
      handleUser(userCredential.user);
    }
    if (provider === 'microsoft') {
      auth.signInWithCustomToken(value).then(handleUserCredential)
        .catch(error => setSigninStatus({ error }));
    }
    else if (provider === 'google') {
      const credential = GoogleAuthProvider.credential(value.idToken, value.accessToken);
      auth.signInWithCredential(credential).then(handleUserCredential)
        .catch(error => setSigninStatus({ error }));
    }
  }

  function errorHandler(setState: React.Dispatch<React.SetStateAction<MembershipResults>>) {
    return function(error: FirestoreError) {
      setState({ error });
    }
  }

  React.useEffect(() => {
    if (!db) return;
    return auth.onAuthStateChanged(handleUser);
  }, [db]);
  const backend = useBackend(functionsUri, storageManager);

  React.useEffect(() => NetInfo.addEventListener(state => setIsConnected(state.isInternetReachable || false)), []);
  React.useEffect(() => { if (user && isConnected) onBackendConnected(backend, user); }, [user?.id, isConnected]);

  React.useEffect(() => {
    if (user && db) {
      const email = user.get('email');
      const invitationsUnsub = email && db.collectionGroup("invitations").where("email", "==", email)
        .onSnapshot(
          snapshot => setInvitations({ snapshot: snapshot }),
          errorHandler(setInvitations));
      const membershipsUnsub = db.collectionGroup("memberships").where("user", "==", user.ref)
        .where('deleted', '==', false)
        .onSnapshot(
          snapshot => setMemberships({ snapshot: snapshot }),
          errorHandler(setMemberships));
      return () => {
        if (invitationsUnsub) invitationsUnsub();
        membershipsUnsub();
      };
    }
  }, [user, db]);

  const { colors } = useTheme();

  if (!isLoadingComplete && db) return null;
  return (
    <CoreComponentsContext.Provider value={{
      View: View as CoreComponentClass,
      Text: (Text as unknown) as CoreComponentClass,
      RawText: RawText as CoreComponentClass,
      colors: { fixture: colors.secondary }
    }}>
      <LanguageContext.Provider value={{
        language: language, setLanguage: setUserLanguage, ph: ph
      }}>
        <BackendContext.Provider value={backend}>
          {!user ?
            <LoginScreen signinState={signinState} onToken={firebaseSignIn} />
            :
            <UserContext.Provider value={{ user, logOut }}>
              {
                !memberships ?
                  <LoadingScreen />
                  :
                  (
                    'error' in memberships ?
                      <ErrorScreen error={memberships as any} />
                      :
                      <ProjectsContext.Provider value={{ projects, setCurrentProject }}>
                        <ProjectContext.Provider value={currentProject}>
                          <Navigation
                            invitations={invitations}
                            projects={projects}
                            theme={theme}
                          />
                        </ProjectContext.Provider>
                      </ProjectsContext.Provider>
                  )
              }
            </UserContext.Provider>
          }
        </BackendContext.Provider>
      </LanguageContext.Provider>
    </ CoreComponentsContext.Provider>
  );
}

export default function App() {
  const [fontsLoaded] = useFonts({ RobotoCondensed_400Regular });
  const onLayoutRootView = React.useCallback(
    async () => { if (fontsLoaded) await SplashScreen.hideAsync() },
    [fontsLoaded]);
  const [language, setLanguage_] = React.useState(Phrases.defaultLang);

  function ph(key: string): Phrases.Phrase {
    return Phrases.safePh(language, key);
  };

  function setLanguage(lang: string) {
    setLanguage_(lang);
    dayjs.locale(lang);
  }

  const { LightTheme, DarkTheme } = adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme
  });

  const CombinedDefaultTheme = {
    ...MD3LightTheme,
    ...LightTheme,
    colors: {
      ...MD3LightTheme.colors,
      ...LightTheme.colors,
    },
  };
  const CombinedDarkTheme = {
    ...MD3DarkTheme,
    ...DarkTheme,
    colors: {
      ...MD3DarkTheme.colors,
      ...DarkTheme.colors,
    },
  };

  const colorScheme = useColorScheme();
  const theme = colorScheme === 'dark' ? CombinedDarkTheme : CombinedDefaultTheme;

  return (
    <LanguageContext.Provider value={{
      language: language, setLanguage: setLanguage, ph: ph
    }}>
      <PaperProvider theme={theme}>
        <SafeAreaView
          style={{ flex: 1, ...(Platform.OS === 'web' ? { height: '100vh' } : {}) }}
          onLayout={onLayoutRootView}
        >
          <ErrorBoundary toplevel>
            <App_ theme={theme} />
          </ErrorBoundary>
        </SafeAreaView>
      </PaperProvider>
    </LanguageContext.Provider>
  );
}
