import { DocumentSnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import { useSeasons } from "../data/useSeasons";
import { getScenePath, sortScenes } from "../common/scene";
import LabelledSelect from "./LabelledSelect";
import { getEpisodePath } from "../common/episode";
import { View, ViewStyle } from "react-native";

function getId(doc: DocumentSnapshot) { return doc.id; }

export type ReferentKind = 'scene' | 'episode';

export default function ReferentSelector({
  kind, referent, onReferentChange, style, includeHistorical = false
}:
  {
    kind: ReferentKind;
    referent?: DocumentSnapshot;
    onReferentChange: (scene: DocumentSnapshot | undefined) => void;
    style?: ViewStyle;
    includeHistorical?: boolean
  }) {
  const { ph } = React.useContext(LanguageContext);
  const seasonId = ((kind === 'scene' ? getScenePath : getEpisodePath)(referent?.ref)).season;
  const seasons = useSeasons(includeHistorical, seasonId);
  const [episodes, setEpisodes] = React.useState<DocumentSnapshot[] | undefined>(kind === 'episode' && referent ? [referent] : []);
  const [scenes, setScenes] = React.useState<DocumentSnapshot[] | undefined>(kind === 'scene' && referent ? [referent] : []);
  const [selectedSeason, setSelectedSeason] = React.useState<DocumentSnapshot>();
  const [selectedEpisode, setSelectedEpisode] = React.useState<DocumentSnapshot>();
  React.useEffect(() => {
    if (seasonId && !selectedSeason) {
      setSelectedSeason(seasons?.find(s => s.id == seasonId));
    }
  }, [seasons, seasonId]);
  React.useEffect(() => {
    if (!selectedEpisode) {
      const id = ((kind === 'scene' ? getScenePath : getEpisodePath)(referent?.ref)).episode;
      setSelectedEpisode(episodes?.find(ep => ep.id == id));
    }
  }, [episodes, kind, referent]);
  React.useEffect(() => {
    if (selectedSeason) {
      setEpisodes(undefined);
      return selectedSeason.ref.collection('episodes').onSnapshot(
        q => setEpisodes(q.docs)
      );
    }
    setEpisodes([]);
  }, [selectedSeason]);
  React.useEffect(() => {
    if (selectedEpisode && kind === 'scene') {
      setScenes(undefined);
      return selectedEpisode.ref.collection('scenes').onSnapshot(
        q => setScenes(sortScenes(q.docs))
      );
    }
    setScenes([]);
  }, [selectedEpisode, kind]);
  return (
    <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 16, ...style }}>
      <View>
        <LabelledSelect<DocumentSnapshot>
          label={ph('season') as string}
          entries={seasons}
          entryKey={getId} entryLabel={getId}
          selectedEntry={selectedSeason}
          onEntryChange={setSelectedSeason}
          autoselect
        />
      </View>
      <View>
        {(!episodes || episodes.length > 0) &&
          <LabelledSelect<DocumentSnapshot>
            label={ph('episode') as string}
            entries={episodes} entryKey={getId} entryLabel={getId}
            selectedEntry={kind === 'episode' ? referent : selectedEpisode}
            onEntryChange={kind === 'episode' ? onReferentChange : setSelectedEpisode}
          />
        }
      </View>
      <View>
        {kind === 'scene' && (!scenes || scenes.length > 0) &&
          <LabelledSelect<DocumentSnapshot>
            label={ph('scene') as string}
            entries={scenes} entryKey={getId} entryLabel={getId}
            selectedEntry={referent}
            onEntryChange={onReferentChange}
          />
        }
      </View>
    </View>
  );
}
