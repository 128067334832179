import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/firebase";
import { MembershipCategory } from "../common/membership";
import { roleCategories } from "./user";

export type ProjectState = {
  project: DocumentReference | undefined
  categories: MembershipCategory[];
  adminCategories: MembershipCategory[];
}

export function projectOfMembership(membership: DocumentSnapshot | undefined) {
  return membership?.ref.parent.parent ?? undefined;
}

export function projectStateFor(membership: DocumentSnapshot | undefined): ProjectState {
  const roles = membership?.get('roles') || {};
  return {
    project: projectOfMembership(membership),
    categories: roleCategories(roles, 'all'),
    adminCategories: roleCategories(roles, 'admin')
  };
}
