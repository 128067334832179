import { DocumentSnapshot, Firestore, User } from "@atgof-firebase/firebase";
import { MembershipCategory } from "../common/membership";

export function getUserRef(db: Firestore, uid: string) {
  return db.doc(`users/${uid}`);
}

export async function onUserSnapshot(
  db: Firestore, user: User, language: string, handler: (doc: DocumentSnapshot) => void
) {
  if (!user.uid) throw Error("UID is missing");
  const ref = getUserRef(db, user.uid);
  let u = null;
  try { u = await ref.get(); } catch { }
  if (u?.get('displayName') === user.displayName &&
    u?.get('email') === user.email &&
    u?.get('language') &&
    u?.get('projectOwnershipQuota')
  ) {
    handler(u);
  }
  else {
    ref.set(
      {
        displayName: user.displayName, email: user.email,
        language: u?.get('language') || language,
        projectOwnershipQuota: 0
      },
      { merge: true });
    handler(await ref.get());
  }
  return ref.onSnapshot(handler);
}

export function roleCategories(roles: any, kind: 'admin' | 'user' | 'all') {
  const filterRoles = kind == 'all' ?
    ((k: string) => roles[k]['user'] === true || roles[k]['admin'] === true) :
    ((k: string) => roles[k][kind] === true);
  return roles ? Object.keys(roles).filter(filterRoles) as MembershipCategory[] : [];
}
