import dayjs from 'dayjs';
import "dayjs/locale/en-gb";
import "dayjs/locale/cy";
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

export type PhraseKey = string;
type PluralPhrase = Record<number | "default", string>;
type PrimitivePhrase = string | PluralPhrase
export type PhraseFunction = (m: any) => PrimitivePhrase;
export type Phrase = PrimitivePhrase | PhraseFunction;
export type Language = string;
type PhraseDefinition = Record<Language, Phrase>;
export type PhraseLookupFunction = (key: PhraseKey) => Phrase;

export const languages = [
  { lang: "en", label: "English", months: dayjs().locale("en").localeData().months() },
  { lang: "cy", label: "Cymraeg", months: dayjs().locale("cy").localeData().months() }
];

export const defaultLang = languages[0].lang;

const appName = "Atgof";

export const TIME_OF_DAY_PHRASE_KEYS = ['day', 'night', 'dawn', 'dusk'] as const;
export type TimeOfDay = typeof TIME_OF_DAY_PHRASE_KEYS[number];

export const phrases: Record<PhraseKey, PhraseDefinition> = {
  "app-name": { "default": appName },
  "website-short": { "default": "atgof.app" },
  "log-in-to-app": { "en": "Log In to " + appName, "cy": "Mewngofnodi i " + appName },
  "settings": { "en": "Settings", "cy": "Dewisiadau" },
  "language": { "en": "Language", "cy": "Iaith" },
  "loading": { "en": "Loading…", "cy": "Wrthi’n llwytho…" },
  "unexpected-error": { "en": "Unexpected Error", "cy": "Gwall Annisgwyl" },
  "toplevel-unexpected-error-prompt": {
    "en": "Please re-open " + appName + ".",
    "cy": "Bydd angen ail-agor " + appName + "."
  },
  "unexpected-error-prompt": {
    "en": "Try going back to another page or re-opening " + appName + ".",
    "cy": "Rhowch dro ar fynd nôl i dudalen arall neu ail-agor " + appName + "."
  },
  "re-open": { "en": "Re-open", "cy": "Ail-agor" },
  "enter-login-details-prompt": {
    "en": "Enter your email address to log in",
    "cy": "Defnyddiwch eich cyfeiriad ebost i fewngofnodi"
  },
  "log-in-prompt": {
    "en": "Tap a button below and then follow any instructions to log in",
    "cy": "Tapiwch fotwm isod ac yna dilyn unrhyw gyfarwyddiadau i fewngofnodi"
  },
  "name": { "en": "Name", "cy": "Enw" },
  "email": { "en": "Email", "cy": "Ebost" },
  "invalid-email-warning": { "en": "Enter a valid address", "cy": "Rhaid rhoi cyfeiriad dilys" },
  "password": { "en": "Password", "cy": "Cyfrinair" },
  "empty-password-warning": { "en": "Enter a password", "cy": "Rhaid rhoi eich cyfrinair" },
  "log-in-with-google": { "en": "Log In with Google", "cy": "Mewngofnodi â Google" },
  "log-in-with-microsoft": { "en": "Log In with Microsoft", "cy": "Mewngofnodi â Microsoft" },
  "log-out": { "en": "Log Out", "cy": "Allgofnodi" },
  "logging-in": { "en": "Logging in", "cy": "Wrthi’n mewngofnodi" },
  "checking-account": { "en": "Checking account", "cy": "Gwirio’r cyfrif" },
  "login-failed": { "en": "Unable to log in", "cy": "Nid oedd modd mewngofnodi" },
  "login-cancelled": { "en": "The log-in process was cancelled", "cy": "Cafodd y mewngofnodi ei ganslo" },
  "check-email-and-password": {
    "en": "Make sure you entered your email address and password correctly.",
    "cy": "Sicrhewch bod eich cyfeiriad ebost a’ch cyfrinair yn gywir."
  },
  "login-failed-with-reason": {
    "en": "Unable to log in. The service provided the following reason:",
    "cy": "Nid oedd modd mewngofnodi. Dyma’r gwall gan y gwasanaeth:"
  },
  "unable-to-load": { "en": "Unable to load " + appName, "cy": "Methu llwytho " + appName },
  "unable-to-load-with-reason": {
    "en": appName + " couldn’t be loaded. The service provided the following reason:",
    "cy": "Nid oedd modd llwytho " + appName + ". Dyma’r gwall gan y gwasanaeth:"
  },
  "invitations-failed-with-reason": {
    "en": "Unable to load any invitations. The service provided the following reason:",
    "cy": "Nid oedd modd llwytho unrhyw wahoddiadau. Dyma’r gwall gan y gwasanaeth:"
  },
  "unable-to": { "en": "Unable to", "cy": "Methu" },
  "unable-to-perform-action": { "en": "Unable to perform action", "cy": "Methu cyflawni’r weithred" },
  "dismiss": { "en": "Dismiss", "cy": "Diddymu" },
  "try-again": { "en": "Please try again.", "cy": "Rhowch gynnig arall arni." },
  "try-again-later": { "en": "Please try again later.", "cy": "Rhowch gynnig arall ymhen ychydig." },
  "create-account": { "en": "Create Account", "cy": "Creu Cyfrif Newydd" },
  "new-invitation": { "en": "New Invitation", "cy": "Gwahoddiad Newydd" },
  "new-member": { "en": "New Member", "cy": "Aelod Newydd" },
  "invited-to-join": { "en": "You are invited to join", "cy": "Gwahoddiad i ymuno â" },
  "a-project": { "en": "a project", "cy": "project" },
  "accept": { "en": "Accept", "cy": "Derbyn" },
  "create-invitation": { "en": "create invitation", "cy": "creu gwahoddiad" },
  "accept-invitation": { "en": "accept invitation", "cy": "derbyn gwahoddiad" },
  "join-a-project": { "en": "Join a Project", "cy": "Ymuno â Project" },
  "no-invitations-explanation": {
    "en": (m => "Please ask your team to invite " + m.email + " to the project you’re working on."),
    "cy": (m => "Gofynnwch i’ch tîm wahodd " + m.email + " i’r project rydych yn gweithio arno.")
  },
  "no-invitations-next-step": {
    "en": "Once they’ve done that, an invitation will appear here for you to accept.",
    "cy": "Unwaith mae hynny wedi’i wneud, bydd eich gwahoddiad yn ymddangos yma.",
  },
  "id-token-required-error": {
    "en": "Unable to authenticate your account. Please log out and log in again.",
    "cy": "Nid oedd modd dilysu eich cyfrif. Ceisiwch allgofnodi a mewngofnodi eto.",
  },
  "camera-permission-required-error": {
    "en": appName + " requires permission to access the camera",
    "cy": "Mae angen caniatâd ar " + appName + " er mwyn defnyddio’r camera",
  },
  "library-permission-required-error": {
    "en": appName + " requires permission to access your photo library",
    "cy": "Mae angen caniatâd ar " + appName + "er mwyn dod o hyd i luniau",
  },
  "search": { "en": "Search", "cy": "Chwilio" },
  "edit": { "en": "Edit", "cy": "Golygu" },
  "select": { "en": "Select", "cy": "Dethol" },
  "select-all": { "en": "Select All", "cy": "Dethol Popeth" },
  "selected": { "en": "selected", "cy": "wedi dethol" },
  "ignore": { "en": "Ignore", "cy": "Anwybyddu" },
  "save": { "en": "Save", "cy": "Arbed" },
  "done": { "en": "Done", "cy": "Iawn" },
  "delete": { "en": "Delete", "cy": "Dileu" },
  "cancel": { "en": "Cancel", "cy": "Canslo" },
  "until": { "en": "until", "cy": "tan" },
  "sheets": { "en": "Sheets", "cy": "Dalennau" },
  "by": { "en": "by", "cy": "yn ôl" },
  "since": { "en": "Since", "cy": "Ers" },
  "date": { "en": "Date", "cy": "Dyddiad" },
  "recent-sheets": { "en": "Recent Sheets", "cy": "Dalennau Diweddar" },
  "my-sheets": { "en": "My Sheets", "cy": "Fy Nalennau" },
  "my-recent-sheets": { "en": "My Recent Sheets", "cy": "Fy Nalennau Diweddar" },
  "new-sheet": { "en": "New Sheet", "cy": "Dalen Newydd" },
  "edit-sheet": { "en": "Edit Sheet", "cy": "Golygu Dalen" },
  "new-arts-sheet": { "en": "New Arts Sheet", "cy": "Dalen Gelf Newydd" },
  "new-arts-look": { "en": "New Arts Look", "cy": "Gwedd Celf Newydd" },
  "new-makeUp-sheet": { "en": "New Make-up Sheet", "cy": "Dalen Golur Newydd" },
  "new-wardrobe-sheet": { "en": "New Wardrobe Sheet", "cy": "Dalen Wisg Newydd" },
  "new-makeUp-look": { "en": "New Make-up Look", "cy": "Gwedd Colur Newydd" },
  "new-wardrobe-look": { "en": "New Wardrobe Look", "cy": "Gwedd Gwisg Newydd" },
  "new-pa-sheet": { "en": "New PA Sheet", "cy": "Dalen PA Newydd" },
  "projects": { "en": "Projects", "cy": "Projectau" },
  "invitation": { "en": "Invitation", "cy": "Gwahoddiad" },
  "invitations": { "en": "Invitations", "cy": "Gwahoddiadau" },
  "member": { "en": "Member", "cy": "Aelod" },
  "members": { "en": "Members", "cy": "Aelodau" },
  "roles": { "en": "Roles", "cy": "Hawliau" },
  "none": { "en": "None", "cy": "Dim" },
  "normal-user": { "en": "Normal user", "cy": "Defnyddiwr cyffredin" },
  "admin-user": { "en": "Admin user", "cy": "Defnyddiwr gweinyddol" },
  "users": { "en": "Users", "cy": "Defnyddwyr" },
  "seasons": { "en": "Seasons", "cy": "Cyfresi" },
  "sets": { "en": "Sets", "cy": "Setiau" },
  "characters": { "en": "Characters", "cy": "Cymeriadau" },
  "episodes": { "en": "Episodes", "cy": "Penodau" },
  "category": { "en": "Category", "cy": "Categori" },
  "admin": { "en": "Admin", "cy": "Gweinyddol" },
  "arts": { "en": "Arts", "cy": "Celf" },
  "makeUp": { "en": "Make-up", "cy": "Colur" },
  "wardrobe": { "en": "Wardrobe", "cy": "Gwisg" },
  "pa": { "en": "PA", "cy": "PA" },
  "script-supervisor": { "en": "Script Supervisor", "cy": "Goruchwyliwr Sgript" },
  "postProduction": { "en": "Post-production", "cy": "Ôl-gynhyrchu" },
  "postProductionDirector": {
    "en": "Post-production Director",
    "cy": "Cyfarwyddwr Ôl-gynhyrchu"
  },
  "postProductionSFX": {
    "en": "Post-production SFX",
    "cy": "SFX Ôl-gynhyrchu"
  },
  "soundSupervisor": { "en": "Sound Supervisor", "cy": "Goruchwyliwr Sain" },
  "directorOfPhotography": { "en": "Director of Photography", "cy": "Cyfarwyddwr Ffotograffiaeth" },
  "dop": { "en": "DoP", "cy": "DoP" },
  "lightingGaffer": { "en": "Lighting Gaffer", "cy": "Giaffar Goleuo" },
  "cateringManager": { "en": "Catering Manager", "cy": "Rheolwr Arlwyo" },
  "wellbeingFacilitator": { "en": "Well-being Facilitator", "cy": "Hwylusydd Lles" },
  "dubbingMixer": {
    "en": "Dubbing Mixer",
    "cy": "Cymathydd Sain"
  },
  "editor": {
    "en": "Editor",
    "cy": "Golygydd"
  },
  "project": { "en": "Project", "cy": "Project" },
  "production": { "en": "Production", "cy": "Cynhyrchiad" },
  "character": { "en": "Character", "cy": "Cymeriad" },
  "set": { "en": "Set", "cy": "Set" },
  "count-season": {
    "en": (m => m.count == 1 ? "season" : "seasons"),
    "cy": (m => m.count == 2 ? "gyfres" : (m.count == 0 ? "cyfresi" : "cyfres")),
  },
  "season": { "en": "Season", "cy": "Cyfres" },
  "series": { "en": "Series", "cy": "Cyfres" },
  "filming-starts": { "en": "Filming Starts", "cy": "Dechrau Ffilmio" },
  "filming-ends": { "en": "Filming Ends", "cy": "Gorffen Ffilmio" },
  "count-episode": {
    "en": (m => m.count == 1 ? "episode" : "episodes"),
    "cy": (m => m.count == 2 ? "bennod" : (m.count == 0 ? "penodau" : "pennod")),
  },
  "episode": { "en": "Episode", "cy": "Pennod" },
  "ep": { "en": "Ep", "cy": "Pen" },
  "zero-count": { "en": "No", "cy": "Dim" },
  "count-scene": {
    "en": (m => m.count == 1 ? "scene" : "scenes"),
    "cy": (m => m.count == 2 ? "olygfa" : (m.count == 0 ? "golygfeydd" : "golygfa")),
  },
  "scene": { "en": "Scene", "cy": "Golygfa" },
  "scenes": { "en": "Scenes", "cy": "Golygfeydd" },
  "runs-into": { "en": "Runs into", "cy": "Rhedeg mewn i" },
  "isComplete": { "en": "Complete", "cy": "Cyflawn" },
  "actions": { "en": "Tasks", "cy": "Tasgau" },
  "isDone": { "en": "Done", "cy": "Gorffen" },
  "description": { "en": "Description", "cy": "Disgrifiad" },
  "end": { "en": "End", "cy": "Diwedd" },
  "tx": { "en": "TX", "cy": "TX" },
  "tx-date": { "en": "TX Date", "cy": "Darlledu" },
  "director": { "en": "Director", "cy": "Cyfarwyddwr" },
  "cast": { "en": "Cast", "cy": "Cast" },
  "actor": { "en": "Actor", "cy": "Actor" },
  "supportingArtist": { "en": "Supporting Artist", "cy": "Ecstra" },
  "chaperone": { "en": "Chaperone", "cy": "Tywysydd" }, // TODO Check this
  "producer": {
    "en": "Producer",
    "cy": "Cynhyrchydd"
  },
  "executiveProducer": {
    "en": "Executive Producer",
    "cy": "Uwch Gynhyrchydd"
  },
  "productionManager": {
    "en": "Production Manager",
    "cy": "Rheolwr Cynhyrchu"
  },
  "productionCoordinator": {
    "en": "Production Co-ordinator",
    "cy": "Cydlynydd Cynhyrchu"
  },
  "1stAssistantDirector": {
    "en": "1st Assistant Director",
    "cy": "Cyfarwyddwr Cynorthwyol 1af"
  },
  "2ndAssistantDirector": {
    "en": "2nd Assistant Director",
    "cy": "2il Gyfarwyddwr Cynorthwyol"
  },
  "3rdAssistantDirector": {
    "en": "3rd Assistant Director",
    "cy": "3ydd Cyfarwyddwr Cynorthwyol"
  },
  "runner": { "en": "Runner", "cy": "Rhedwr" },
  "locationManager": { "en": "Location Manager", "cy": "Rheolwr Lleoliadau" },
  "artSupervisor": { "en": "Art Supervisor", "cy": "Goruchwyliwr Celf" },
  "artAssistant": { "en": "Art Assistant", "cy": "Cynorthwyydd Celf" },
  "designer": { "en": "Designer", "cy": "Cynllunydd" },
  "graphicDesigner": { "en": "Graphic Designer", "cy": "Dylunydd Graffeg" },
  "fightDirector": { "en": "Fight Director", "cy": "Cyfarwyddwr yr Ymladd" },
  "intimacyCoordinator": { "en": "Intimacy Co-ordinator", "cy": "Cydlynydd Agosatrwydd" },
  "sustainabilityCoordinator": { "en": "Sustainability Co-ordinator", "cy": "Cydlynydd Cynaliadwyedd" },
  "animalHandler": { "en": "Animal Handler", "cy": "Arbenigwr Trin Anifeiliaid" },
  "wardrobeSupervisor": { "en": "Wardrobe Supervisor", "cy": "Goruchwyliwr Gwisg" },
  "wardrobeAssistant": { "en": "Wardrobe Assistant", "cy": "Cynorthwyydd Gwisg" },
  "makeUpSupervisor": { "en": "Make-up Supervisor", "cy": "Goruchwyliwr Colur" },
  "makeUpAssistant": { "en": "Make-up Assistant", "cy": "Cynorthwyydd Colur" },
  "synopsis": { "en": "Synopsis", "cy": "Crynodeb" },
  "filming-date": { "en": "Filming Date", "cy": "Dyddiad Filmio" },
  "interior": { "en": "Interior", "cy": "Mewn" },
  "exterior": { "en": "Exterior", "cy": "Allan" },
  "int-ext": { "en": "Int/Ext", "cy": "Me/All" },
  "int": { "en": "Int", "cy": "Me" },
  "ext": { "en": "Ext", "cy": "All" },
  "tod": { "en": "ToD", "cy": "Adeg" },
  "day-ordinal": { "en": "Day", "cy": "Diwrnod" },
  "day": { "en": "Day", "cy": "Dydd" },
  "night": { "en": "Night", "cy": "Nos" },
  "dawn": { "en": "Dawn", "cy": "Gwawr" },
  "dusk": { "en": "Dusk", "cy": "Cyfnos" },
  "pages": { "en": "Pages", "cy": "Tudalennau" },
  "notes": { "en": "Notes", "cy": "Nodiadau" },
  "extras": { "en": "Extras", "cy": "Ecstras" },
  "makeUp-sheet": { "en": "Make-up Sheet", "cy": "Dalen Colur" },
  "choose...": { "en": "Choose…", "cy": "Dewis…" },
  "photos": { "en": "Photos", "cy": "Lluniau" },
  "photo": { "en": "Photo", "cy": "Llun" },
  "looks": { "en": "Looks", "cy": "Gweddau" },
  "look": { "en": "Look", "cy": "Gwedd" },
  "episode-look": { "en": "Episode Look", "cy": "Gwedd Pennod" },
  "new-look": { "en": "New Look", "cy": "Gwedd Newydd" },
  "unnamed-look": { "en": "[ Unnamed Look ]", "cy": "[ Gwedd Di-enw ]" },
  "look-name": { "en": "Look Name", "cy": "Enw Gwedd" },
  "props": { "en": "Props", "cy": "Props" },
  "prop": { "en": "Prop", "cy": "Prop" },
  "new-prop": { "en": "New Prop", "cy": "Prop Newydd" },
  "unnamed-prop": { "en": "[ Unnamed Prop ]", "cy": "[ Prop Di-enw ]" },
  "prop-name": { "en": "Prop Name", "cy": "Enw Prop" },
  "makeUp:notes": { "en": "Notes", "cy": "Nodiadau" },
  "makeUp:other": { "en": "Other", "cy": "Arall" },
  "makeUp:primer": { "en": "Primer", "cy": "Preimiwr" },
  "makeUp:base": { "en": "Base", "cy": "Sylfaen" },
  "makeUp:concealer": { "en": "Concealer", "cy": "Cuddiwr" },
  "makeUp:blusher": { "en": "Blusher", "cy": "Powdwr Gwrido" },
  "makeUp:powder": { "en": "Powder", "cy": "Powdwr" },
  "makeUp:eyeshadow": { "en": "Eyeshadow", "cy": "Colur Llygaid" },
  "makeUp:eyeliner-top": { "en": "Eye-liner Top", "cy": "Pensel Linellu Top" },
  "makeUp:eyeliner-bottom": { "en": "Eye-liner Bottom", "cy": "Pensel Linellu Gwaelod" },
  "makeUp:mascara": { "en": "Mascara", "cy": "Masgara" },
  "makeUp:eyebrows": { "en": "Eyebrows", "cy": "Aeliau" },
  "makeUp:lips": { "en": "Lips", "cy": "Gwefusau" },
  "makeUp:nails": { "en": "Nails", "cy": "Ewinedd" },
  "makeUp:makeup-notes": { "en": "Make-up Notes", "cy": "Nodiadau Colur" },
  "hair": { "en": "Hair", "cy": "Gwallt" },
  "makeUp:hair-notes": { "en": "Hair Notes", "cy": "Nodiadau Gwallt" },
  "makeUp:hair-products": { "en": "Hair Products", "cy": "Cynhyrchion Gwallt" },
  "makeUp:finishing-product": { "en": "Finishing Product", "cy": "Cynnyrch Gorffennu" },
  "makeUp:hair-accessories": { "en": "Hair Accessories", "cy": "Ategolion Gwallt" },
  "wardrobe:tops": { "en": "Tops", "cy": "Topiau" },
  "wardrobe:bottoms": { "en": "Bottoms", "cy": "Gwaelodion" },
  "wardrobe:footwear": { "en": "Footwear", "cy": "Esgidiau" },
  "wardrobe:outerwear": { "en": "Outerwear", "cy": "Dillad Uchaf" },
  "wardrobe:accessories": { "en": "Accessories", "cy": "Ategolion" },
  "wardrobe:notes": { "en": "Notes", "cy": "Nodiadau" },
  "arts:action-props": { "en": "Action Props", "cy": "Props Llaw" },
  "arts:dressing-props": { "en": "Dressing Props", "cy": "Props Set" },
  "arts:food": { "en": "Food", "cy": "Bwyd" },
  "arts:vehicles": { "en": "Vehicles", "cy": "Cerbydau" },
  "arts:animals": { "en": "Animals", "cy": "Anifeiliaid" },
  "arts:graphics": { "en": "Graphics", "cy": "Graffigwaith" },
  "arts:director-notes": { "en": "Director’s Notes", "cy": "Nodiadau Cyfarwyddwr" },
  "arts:notes": { "en": "Notes", "cy": "Nodiadau" },
  "location": { "en": "Location", "cy": "Lleoliad" },
  "card-number": { "en": "Card Number", "cy": "Rhif Cardyn" },
  "camera": { "en": "Camera", "cy": "Camera" },
  "pa:sound": { "en": "Sound", "cy": "Sain" },
  "pa:slate": { "en": "Slate", "cy": "Llechen" },
  "sync": { "default": "Sync" },
  "mute": { "default": "Mute" },
  "wildtrack": { "default": "Wildtrack" },
  "guidetrack": { "default": "Guidetrack" },
  "pa:takes": { "en": "Takes", "cy": "Cynigion" },
  "take": { "en": "Take", "cy": "Cynnig" },
  "timecode": { "default": "Timecode" },
  "details": { "en": "Details", "cy": "Manylion" },
  "length": { "en": "Length", "cy": "Hyd" },
  "pa:description": { "en": "Description", "cy": "Disgrifiad" },
  "pa:dialogue": { "en": "Dialogue", "cy": "Deialog" },
  "pa:notes-for-the-editor": { "en": "Notes for the Editor", "cy": "Nodiadau i’r Golygydd" },
  "pa:sound-details": { "en": "Sound Details", "cy": "Manylion Sain" },
  "script": { "en": "Script", "cy": "Sgript" },
  "select-script-starting-point": {
    "en": "Tap on the script to select a starting point",
    "cy": "Tapiwch ar y sgript i ddethol man cychwyn"
  },
  "select-script-ending-point": {
    "en": "Tap on the script to select an ending point",
    "cy": "Tapiwch ar y sgript i ddethol man gorffen"
  },
  "in": { "en": "IN", "cy": "MEWN" },
  "out": { "en": "OUT", "cy": "ALLAN" },
  "pref": { "en": "★", "cy": "★" },
  "import": { "en": "Import", "cy": "Llwytho" },
  "import-all": { "en": "Import All", "cy": "Llwytho Popeth" },
  "importing": { "en": "Importing", "cy": "Llwytho" },
  "importing-all": { "en": "Importing All…", "cy": "Llwytho Popeth…" },
  "import-complete": { "en": "Import complete", "cy": "Wedi gorffen llwytho" },
  "import-file": { "en": "Import File", "cy": "Llwytho Ffeil" },
  "import-files": { "en": "Import Files", "cy": "Llwytho Ffeiliau" },
  "upload": { "en": "Upload", "cy": "Llwytho" },
  "uploads-not-possible": {
    "en": "Uploads are only possible from a computer. Please ask your team for assistance.",
    "cy": "Dim ond o gyfrifiadur mae modd llwytho ffeiliau i mewn. Gofynnwch i’ch tîm am gymorth."
  },
  "drag-prompt": {
    "en": (m => "Drag " + m.fileTypeList + " files here"),
    "cy": (m => "Llusgwch ffeiliau " + m.fileTypeList + " yma")
  },
  "or": { "en": "or", "cy": "neu" },
  "choose-files": { "en": "Choose Files…", "cy": "Dewis Ffeiliau…" },
  "retry": { "en": "Retry", "cy": "Ail-gynnig" },
  "load": { "en": "Load", "cy": "Llwytho" },
  "rename-to": { "en": "Rename to…", "cy": "Ail-enwi i…" },
  "block": { "en": "Block", "cy": "Bloc" },
  "hide": { "en": "Hide", "cy": "Cuddio" },
  "show": { "en": "Show", "cy": "Dangos" },
  "app-version": { "en": appName + " Version", "cy": "Fersiwn " + appName },
  "software-update-prompt": {
    "en": "A new version of " + appName + " is available",
    "cy": "Mae fersiwn newydd o " + appName + " ar gael"
  },
  "reopen-app-prompt": {
    "en": "Please close and re-open " + appName + " to load the latest version",
    "cy": "Caewch ac ail-agor " + appName + " i lwytho’r fersiwn newydd"
  },
  "check-for-updates": {
    "en": "Check for Updates",
    "cy": "Adnewyddu"
  },
  "downloads": { "en": "Downloads", "cy": "Lawrlwythiadau" },
  "download": { "en": "Download", "cy": "Lawrlwytho" },
  "created-by": { "en": "Created by", "cy": "Crëwyd gan" },
  "created-at": { "en": "Created at", "cy": "Crëwyd" },
  "last-modified": { "en": "Last modified", "cy": "Newidiad diweddaraf" },
  "teaser": { "en": "Teaser", "cy": "Rhagflas" },
  "breakdown": { "en": "Breakdown", "cy": "Breakdown" },
  "timings": { "en": "Timings", "cy": "Amseru" },
  "timings:pa": { "en": "PA", "cy": "PA" },
  "timings:rehearsal": { "en": "Rehearsal", "cy": "Ymarfer" },
  "timings:shot": { "en": "Shot", "cy": "Saethu" },
  "timings:edit": { "en": "Edit", "cy": "Golygu" },
  "running-total": { "en": "Running Total", "cy": "Cyfanswm wrth fynd" },
  "schedule": { "en": "Schedule", "cy": "Amserlen" },
  "team": { "en": "Team", "cy": "Tîm" },
  "teams": { "en": "Teams", "cy": "Timau" },
  "default-team": { "en": "Default Team", "cy": "Tîm Diofyn" },
  "synchronising": { "en": "Synchronising", "cy": "Wrthi’n trosglwyddo" },
  "waiting-to-synchronise": { "en": "Waiting to synchronise", "cy": "Aros i drosglwyddo newidiadau" },
  "synchronising-your-changes": { "en": "Synchronising your changes…", "cy": "Trosglwyddo’ch newidiadau…" },
  "no-internet-connection": { "en": "No internet connection", "cy": "Dim cysylltiad i’r rhyngrwyd" },
  "count-file": {
    "en": (m => m.count == 1 ? "file" : "files"),
    "cy": (_ => "ffeil"),
  },
  "count-thumbnail": {
    "en": (m => m.count == 1 ? "thumbnail" : "thumbnails"),
    "cy": (_ => "bach"),
  },
  "full-size": {
    "en": "full size",
    "cy": "maint llawn",
  },
  "uploading": { "en": "Uploading", "cy": "Lanlwytho" },
  "waiting-to-upload": { "en": "Waiting to upload", "cy": "Aros i lanlwytho" },
  "failed-to-upload": { "en": "Failed to upload", "cy": "Methu lanlwytho" },
  "downloading": { "en": "Downloading", "cy": "Lawrlwytho" },
  "waiting-to-download": { "en": "Waiting to download", "cy": "Aros i lawrlwytho" },
  "generate-pdf": { "en": "Generate PDF", "cy": "Creu PDF" },
  "generating-pdf": { "en": "Generating PDF", "cy": "Wrthi’n creu PDF" },
  "unable-to-generate-pdf": { "en": "Unable to generate PDF", "cy": "Methu â chreu PDF" },
  "download-pdf": { "en": "Download PDF", "cy": "Lawrlwytho PDF" },
  "back-to": { "en": "Back to", "cy": "Yn ôl at" },
  "on-to": { "en": "On to", "cy": "Ymlaen at" },
  "unscheduled": { "en": "Unscheduled", "cy": "Heb eu hamserlennu" },
  "layers": { "en": "Layers", "cy": "Haenau" },
  "tramlines": { "en": "Tramlines", "cy": "Tramlines" },
  "reactGrid-legacyBrowserHeader": {
    "en": "Please use a more up-to-date web browser",
    "cy": "Defnyddiwch borwr gwe mwy diweddar"
  },
  "reactGrid-legacyBrowserText": {
    "en": "Please make sure you browser is fully updated or try a different browser. We recommend using the most recent release of Google Chrome, Microsoft Edge, Firefox or Safari.",
    "cy": "Gwnewch yn siŵr eich bod yn defnyddio porwr gwe digon diweddar. Rydym yn argymell defnyddio fersiwn ddiweddaraf Google Chrome, Microsoft Edge, Firefox neu Safari."
  },
  "reactGrid-macOsContextMenuPasteAlert": {
    "en": "Use ⌘ C for copy, ⌘ X for cut, and ⌘ V for paste.",
    "cy": "Defnyddiwch ⌘ C i gopïo, ⌘ X i dorri, a ⌘ V i ludo."
  },
  "reactGrid-otherBrowsersContextMenuPasteAlert": {
    "en": "Use Ctrl-C for copy, Ctrl-X for cut, and Ctrl-V for paste.",
    "cy": "Defnyddiwch Ctrl-C i gopïo, Ctrl-X i dorri, a Ctrl-V i ludo."
  },
  "reactGrid-contextMenuPasteAlert": {
    "en": "This action is not supported in this browser.",
    "cy": "Nid oes modd i’ch porwr gwe presennol gyflawni’r weithred hon."
  },
  "copy": {
    "en": "Copy",
    "cy": "Copïo"
  },
  "cut": {
    "en": "Cut",
    "cy": "Torri"
  },
  "paste": {
    "en": "Paste",
    "cy": "Gludo"
  },
  "unknown": { "en": "Unknown", "cy": "Anhysbys" },
  "free": { "en": "free", "cy": "ar gael" }
};

function ph_(language: string, key: PhraseKey): Phrase {
  const definition = phrases[key];
  return definition && definition[language];
}

export function ph(language: string, key: PhraseKey): Phrase {
  const phrase = ph_(language, key) || ph_("default", key);
  if (!phrase) throw new Error("Phrase " + key + " not found in " + language);
  return phrase;
};

export function safePh(language: string, key: PhraseKey): Phrase {
  return ph_(language, key) || ph_("default", key) || ph_("en", key);
}

export function listOfItems(combinationWord: string, items: string[]) {
  const lastIdx = items.length - 1;
  if (lastIdx < 0) return '';
  if (lastIdx == 0) return items[0];
  return items.slice(0, lastIdx).join(', ') + ' ' + combinationWord + ' ' + items[lastIdx];
}
