import React from "react";
import { LanguageContext } from "../common/language";
import { formatDate } from "../common/util";
import { StyleProp, View, ViewStyle } from "react-native";
import { DocumentReference, DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import { useProject } from "../data/useProject";
import { TeamSelector } from "./TeamSelector";
import { Text } from "react-native-paper";
import { SceneData, Scene } from "../common/scene";
import { AttrStack } from "../common/AttrStack";
import { SceneLocation, SceneStatusView } from "../common/SceneInfo";

export default function SceneInfo(
  { scene, style, sceneRefToEdit, handleTeamChange, excludeSynopsis, excludeFilmingDate }:
    {
      scene: SceneData | Scene;
      style?: StyleProp<ViewStyle>;
      sceneRefToEdit?: DocumentReference;
      handleTeamChange?: (team: DocumentReference | undefined) => void;
      excludeSynopsis?: boolean;
      excludeFilmingDate?: boolean
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const [team, setTeam] = React.useState<DocumentSnapshot>();
  React.useEffect(() => {
    if (sceneRefToEdit && project) {
      if ('teamRef' in scene && scene.teamRef) return scene.teamRef.onSnapshot(setTeam); // TODO Probably unnecessary given that scene.teamData may be set; check all callers of SceneInfo
      setTeam(undefined);
    }
  }, [project?.id, sceneRefToEdit?.path, scene.team]);
  const onTeamChange = React.useCallback((newTeam: DocumentSnapshot | undefined) => {
    if (sceneRefToEdit && newTeam) {
      sceneRefToEdit.update({ team: newTeam?.ref || FieldValue.delete() }).then(() => {
        if (handleTeamChange) handleTeamChange(newTeam?.ref);
      });
    }
  }, [sceneRefToEdit?.path, handleTeamChange]);
  return (
    <View style={style}>
      <SceneStatusView scene={scene} />
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <SceneLocation scene={scene} fontSize={16} />
      </View>
      <Text style={{ fontSize: 14, marginBottom: 2, marginTop: 2 }}>{scene.cast?.join(', ')}</Text>
      {!excludeSynopsis && <Text>{scene.synopsis}</Text>}
      {!excludeFilmingDate &&
        <AttrStack small hideEmpties attrs={[
          {
            label: ph('filming-date') as string,
            value: formatDate(scene.filmingDate, true)
          }
        ]}
        />
      }
      <AttrStack small hideEmpties attrs={[
        { label: ph('tod') as string, value: scene.timeOfDay || '' },
        //{ label: ph('pages') as string, value: scene.pages }
      ]} />
      <AttrStack small hideEmpties attrs={[
        {
          label: ph('extras') as string,
          value: (('extras' in scene ? scene.extras : undefined) || []).join(', ')
        }
      ]} />
      {sceneRefToEdit &&
        <View style={{ flexDirection: 'row' }}>
          <TeamSelector
            selectedTeam={team} onTeamChange={onTeamChange}
          />
        </View>
      }
    </View>
  );
}
