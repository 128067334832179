import { DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import { FIELD_SPECS, SheetCategory, sheetsCollection } from "../common/sheet";
import { Text } from "react-native-paper";
import { TextStyle } from "react-native";
import { useProject } from "../data/useProject";
import { UserContext } from "../data/userContext";
import { keysForField } from "../common/helpers/fields";
import { episodeForScene } from "../common/episode";
import { Scene } from "../common/scene";
import { MembershipCategory } from "../common/membership";
import { ProjectContext } from "../data/projectContext";
import { CellRenderProps, ScenesTable, ScenesTableColumnGroup, ScenesTableSection, locationColumn, sceneColumn } from "./ScenesTable";
import { Editable } from "./Editable";
import { ScriptButton } from "./ScriptButton";
import { SceneButton } from "./SceneButton";

export type BreakdownTableSection = ScenesTableSection;

type SheetColumn = {
  category: SheetCategory,
  attr: string;
  labelK: string;
  isMulti: boolean
}

function sheetColumnsForCategory(category: MembershipCategory): SheetColumn[] {
  const sections = FIELD_SPECS[category];
  let columns: SheetColumn[] = [];
  const sectionKs = Object.keys(sections ?? {});
  for (const sectionK of sectionKs) {
    for (const field of sections[sectionK]) {
      const { k, labelK } = keysForField(field, category);
      if ('tableColumn' in field && k) {
        const tableColumn = field['tableColumn'];
        if (tableColumn && k && labelK) {
          columns.push({
            category: category as SheetCategory,
            labelK,
            attr: k, isMulti: field.multi !== undefined
          });
        }
      }
    }
  }
  return columns;
}

function EditableCell({ attr, readonly, isMulti, scene, snapshot, category, character }: {
  attr: string;
  readonly: boolean;
  isMulti: boolean;
  scene: Scene;
  category: SheetCategory;
  snapshot: DocumentSnapshot | undefined;
  character: string | undefined;
}) {
  const project = useProject();
  const { user } = React.useContext(UserContext);
  const value = snapshot?.get(attr);
  const ref = React.useMemo(
    () => snapshot?.ref ?? (project ? sheetsCollection(project).doc() : undefined),
    [snapshot?.ref, project]
  );
  const exists = snapshot !== undefined;
  const onChange = React.useCallback(
    (newValue: string[] | string) => {
      if (value === newValue || (!exists && newValue.length == 0)) return;
      const { sets } = scene;
      const modFields = {
        lastModifiedBy: user.ref,
        lastModifiedAt: FieldValue.serverTimestamp(),
        [attr]: newValue
      };
      if (exists) ref?.update(modFields);
      else ref?.set({
        scene: scene.ref,
        episode: episodeForScene(scene.ref),
        ...(!character && sets?.length ? { set: sets[0] } : {}), // TODO
        ...(character ? { character } : {}),
        category,
        deleted: false,
        createdBy: user.ref,
        createdAt: FieldValue.serverTimestamp(),
        ...modFields
      });
    },
    [value, ref, exists, scene, user]
  );
  const style: TextStyle = { alignSelf: 'flex-start' };
  if (isMulti) { // TODO This could do with more robust support
    const values: string[] = value ?? [""];
    return values.map((v, i) =>
      <Editable
        key={i}
        style={style}
        readonly={readonly}
        text={v}
        onChange={newValue => {
          let values_ = [...values];
          values_[i] = newValue;
          onChange(values_);
        }}
      />);
  }
  return (
    <Editable
      style={style}
      readonly={readonly}
      text={value ?? ""}
      onChange={onChange}
    />
  );
}

export function BreakdownTable({ sections }: { sections: BreakdownTableSection[] }) {
  const { ph } = React.useContext(LanguageContext);
  const { categories, adminCategories } = React.useContext(ProjectContext);
  const columnGroups = React.useMemo<ScenesTableColumnGroup[]>(
    () => [
      {
        key: 'core',
        kind: 'scene',
        columns: [
          sceneColumn(
            ph,
            scene => <React.Fragment>
              <SceneButton scene={scene} />
              <ScriptButton parent={scene} />
            </React.Fragment>
          ),
          locationColumn(ph)
        ]
      },
      {
        key: 'character',
        kind: 'character',
        columns: [
          {
            key: 'character',
            label: ph('character') as string,
            render: ({ item }: CellRenderProps<string>) => <Text>{item}</Text>
          }
        ]
      },
      ...categories.map(category => {
        return {
          key: category,
          kind: { sheetCategory: category as SheetCategory }, // TODO Shouldn't really cast this here
          label: ph(category) as string,
          columns: sheetColumnsForCategory(category).map(({ labelK, attr, isMulti }) => ({
            key: attr,
            label: ph(labelK) as string,
            render: ({
              item,
              scene,
              category,
              character
            }: CellRenderProps<DocumentSnapshot | undefined>) =>
              category ?
                <EditableCell
                  readonly={!adminCategories.includes(category)}
                  character={character}
                  attr={attr}
                  isMulti={isMulti}
                  scene={scene}
                  category={category}
                  snapshot={item} /> :
                null
          }))
        };
      })
    ],
    [ph, categories]
  );
  return <ScenesTable sections={sections} columnGroups={columnGroups} />;
}
