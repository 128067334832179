import React from "react";
import { PhraseKey } from "../common/phrases";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import LabelledSelect from "./LabelledSelect";
import { SelectConfigProps } from "./Select";
import { useTeams } from "../data/team";

export function TeamSelector(
  { labelPh, selectedTeam, onTeamChange, ...props }:
    {
      labelPh?: PhraseKey
      selectedTeam: DocumentSnapshot | undefined;
      onTeamChange: (team: DocumentSnapshot | undefined) => void
    } & SelectConfigProps
) {
  const { ph } = React.useContext(LanguageContext);
  const teams = useTeams();
  return (
    <LabelledSelect
      {...props}
      label={ph(labelPh ?? 'team') as string}
      entries={teams} entryKey={({ id }) => id}
      entryLabel={doc => doc.get('displayName')}
      selectedEntry={selectedTeam} onEntryChange={onTeamChange} autoselect
    />
  );
}
