import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  DocumentData, DocumentReference, DocumentSnapshot, FieldValue
} from "@atgof-firebase/firebase";
import React from "react";
import { MembershipRoles } from "../common/membership";
import { RootStackParamList } from "../types";
import MembershipDetailPage from "../components/pages/MembershipDetailPage";
import { UserContext } from "../data/userContext";
import { useProject } from "../data/useProject";
import { useNavigation } from "@react-navigation/native";
import { ProgressBar } from "react-native-paper";

export default function MembershipScreen({ route }:
  NativeStackScreenProps<RootStackParamList, 'membership'>
) {
  const { itemId, isInvitation } = route.params;
  const { goBack } = useNavigation();
  const project = useProject();
  const userContext = React.useContext(UserContext);
  const [itemRef, setItemRef] = React.useState<DocumentReference | undefined>(undefined);
  React.useEffect(
    () => setItemRef(project!.collection(isInvitation ? 'invitations' : 'memberships').doc(itemId)),
    [project, isInvitation, itemId]
  );
  const readOnly = !isInvitation && itemRef?.id === userContext.user.id;
  const [item, setItem] = React.useState(undefined as DocumentSnapshot<DocumentData> | undefined);
  React.useEffect(() => itemRef?.onSnapshot(setItem), [itemRef]);
  function updateRoles(newRoles: MembershipRoles) {
    if (itemRef && item) itemRef.update({ roles: newRoles, lastModifiedBy: userContext.user.ref });
  }
  function deleteItem() {
    if (!itemRef) return;
    if (isInvitation) itemRef.delete();
    else itemRef.update({ deleted: FieldValue.serverTimestamp(), lastModifiedBy: userContext.user.ref });
    goBack();
  }
  if (!item) return <ProgressBar indeterminate style={{ width: 800 }} />;
  return (
    <MembershipDetailPage
      readOnly={readOnly} isLoading={item === undefined}
      isInvitation={isInvitation}
      userName={item?.get('displayName')} email={item?.get('email')}
      roles={item?.get('roles')}
      onRolesChanged={readOnly ? undefined : updateRoles}
      deleteAction={readOnly ? undefined : deleteItem}
    />
  );
}
