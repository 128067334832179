import { View } from "react-native";
import FixtureLabel from "../common/FixtureLabel";
import Select, { SelectProps } from "./Select";

export default function LabelledSelect<T>(props: SelectProps<T> & { label: string }) {
  return (
    <View>
      <FixtureLabel text={props.label} style={{ marginBottom: 8 }} />
      <Select<T> {...props} />
    </View>
  );
}
