import { View } from "react-native";
import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/firebase";
import FieldsRow from "./fields/FieldsRow";
import React from "react";
import { useProject } from "../data/useProject";
import { LanguageContext } from "../common/language";
import { sheetColour } from "../common/sheet";
import { sheetsCollection } from "../common/sheet";
import { useSheets } from "../data/useSheets";
import { Text } from "react-native-paper";
import { takeRow, getRows } from "../common/sheet/pa";

const rows = getRows(false);

function Take({ sheet, take }: { sheet: DocumentSnapshot, take: DocumentSnapshot }) {
  return (
    <View>
      {rows.map((fields, i) =>
        <FieldsRow key={i} doc={sheet} category="pa" fields={fields} readonly />
      )}
      <FieldsRow doc={take} fields={takeRow} readonly />
    </View>
  );
}

function GraphicsEntries({ scene }: { scene: DocumentReference | undefined }) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const [sheets, setSheets] = React.useState<DocumentSnapshot[]>();
  React.useEffect(
    () => {
      if (scene && project) {
        return sheetsCollection(project)
          .where('scene', '==', scene).where('category', '==', 'arts')
          .where('deleted', '==', false)
          .onSnapshot(snapshot => setSheets(snapshot.docs));
      }
      setSheets(undefined);
    },
    [scene?.path, project?.path]
  );
  const notes: string[] =
    (sheets || []).flatMap(sheet => ((sheet.get('arts:graphics') as string[] | undefined) || []));
  if (notes.length == 0) return null;
  return (
    <View>
      <Text variant="headlineMedium">{ph('arts:graphics') as string}</Text>
      {notes.map((text, i) => <Text key={i}>{text}</Text>)}
    </View>
  );
}

export function TakeInfo({ sheet, take }: { sheet: DocumentSnapshot | undefined; take: DocumentSnapshot | undefined }) {
  const { ph } = React.useContext(LanguageContext);
  if (!sheet || !take) return null;
  const slate: string | undefined = sheet?.get('pa:slate');
  const index: number | undefined = take?.get('index');
  const isPickup: boolean | undefined = take?.get('isPickup');
  return (
    <View style={{ gap: 4 }}>
      <View style={{ flexDirection: "row", gap: 4 }}>
        <Text variant="headlineSmall">
          {`${slate}/${index !== undefined ? index + 1 : ''}${isPickup ? 'PU' : ''}`}
        </Text>
        {take.get('preferred') ?
          <Text style={{ fontSize: 24, fontWeight: "bold", color: sheetColour(sheet) }}>
            {ph('pref') as string}
          </Text> :
          null
        }
      </View>
      <Take sheet={sheet} take={take} />
    </View>
  );
}


export function Takes({ sheet }: { sheet: DocumentSnapshot | undefined }) {
  const [takes, setTakes] = React.useState<DocumentSnapshot[]>();
  React.useEffect(
    () => sheet?.ref.collection('takes').where('deleted', '==', false)
      .orderBy('index', 'asc')
      .onSnapshot(({ docs }) => setTakes(docs)),
    [sheet?.ref.path]);
  if (!sheet) return null;
  return (
    <View style={{ gap: 16, marginLeft: 16 }}>
      {takes?.map(take => <TakeInfo key={take.id} sheet={sheet} take={take} />)}
    </View>
  );
}

export function PostProductionInfo({ scene }: { scene: DocumentReference | undefined }) {
  const { ph } = React.useContext(LanguageContext);
  const sheets = useSheets(scene, ['pa']);
  return (
    <View style={{ gap: 16, margin: 16 }}>
      {scene && <GraphicsEntries scene={scene} />}
      <View>
        <Text variant="headlineMedium">{ph('pa:takes') as string}</Text>
        {sheets?.sort( // TODO Better sort function here
          (a: DocumentSnapshot, b: DocumentSnapshot) => {
            const [slateA, slateB] = [a, b].map(doc => doc.get('pa:slate'));
            if (slateA && slateB) return slateA.localeCompare(slateB);
            if (slateA) return 1;
            return -1;
          })
          .map(sheet => <Takes key={sheet.id} sheet={sheet} />)}
      </View>
    </View>
  );
}
