import { DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import React, { FunctionComponent, FunctionComponentElement } from "react";
import { LanguageContext } from "../common/language";
import { UserContext } from "../data/userContext";
import { Button, Text } from "react-native-paper";

export type ImporterComponentProps = {
  isImporting: boolean;
  content: any;
  importEntry: DocumentSnapshot | undefined;
  importButton: ((context: undefined | object) => FunctionComponentElement<{}>) | undefined
}

export function Importer(
  { importEntry, kind, component, content }: {
    importEntry: DocumentSnapshot | undefined;
    kind: string | undefined;
    component: FunctionComponent<ImporterComponentProps>;
    content: any
  }) {
  const { ph } = React.useContext(LanguageContext);
  const { user } = React.useContext(UserContext);
  const importComplete = importEntry?.get('importComplete');
  const isImporting = importEntry?.get('importRequestAt') !== undefined;
  const getImportButton = React.useCallback(
    (context: undefined | object) =>
      importComplete ?
        <Text variant="titleMedium" style={{ textAlign: "center" }}>
          {ph('import-complete') as string}
        </Text>
        :
        <Button
          mode="contained"
          disabled={context === undefined}
          loading={isImporting}
          onPress={
            () => importEntry?.ref.update({
              importRequestAt: FieldValue.serverTimestamp(),
              lastModifiedBy: user.ref,
              ...(kind ? { kind: kind } : {}),
              ...(context ? { importContext: context } : {})
            })
          }
        >
          {ph(isImporting ? 'importing-all' : 'import-all') as string}
        </Button>,
    [isImporting, importComplete, importEntry?.ref.path, user.ref.path, kind]
  );
  const button = importEntry ? getImportButton : undefined;
  return React.createElement(component,
    { isImporting: isImporting, content: content, importButton: button, importEntry }
  )
}
