import { QueryDocumentSnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import ListItem from "../ListItem";
import { sheetSubjectCollection } from "../../data/sheet";
import { useProject } from "../../data/useProject";
import ListPage from "./ListPage";
import { CollectionListSpec } from "../CollectionList";
import { SheetSubjectKind } from "../../common/sheet-subject";

type SheetSubject = { id: string; displayName: string };

export default function SheetSubjectListPage({ kind }: { kind: SheetSubjectKind }) {
  const navigation = useNavigation();
  const project = useProject();
  function renderSubject({ item }: { item: SheetSubject }) {
    return (
      <ListItem label={item.displayName}
        onPress={() =>
          navigation.navigate('sheetSubjectDetail', {
            id: item.id,
            subjectKind: kind,
            subjectName: item.displayName
          })}
      />
    );
  }
  function mapItem(item: QueryDocumentSnapshot) {
    return { id: item.id, displayName: item.get('displayName') };
  }
  const [collections, setCollections] = React.useState<CollectionListSpec<SheetSubject, void>[]>();
  React.useEffect(() => {
    setCollections(
      project ?
        [{
          collection: sheetSubjectCollection(project, kind),
          addConstraints: q => q.orderBy('displayName', 'asc'),
          process: (docs: QueryDocumentSnapshot[]) => docs.map(mapItem),
          renderItem: renderSubject,
          searchTokens: item => [item.displayName]
        }] :
        undefined);
  }, [project?.path, kind]);

  return <ListPage collections={collections} estimatedItemSize={52} />;
}
