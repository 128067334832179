import React from "react";
import { Platform, Text as RNText, View } from "react-native";
import { Text } from "react-native-paper";
import { episodePhrases, extractPrefixed, processIdentifier, processScript } from "../common/file-import";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import { ImporterComponentProps } from "../screens/Importer";
import { Revealer } from "./Revealer";
import ScriptParagraph from "../common/ScriptParagraph";
import SeasonSelector from "./SeasonSelector";

function parseEpisodeIdFromFilename(importEntry: DocumentSnapshot | undefined) {
  const metadata: { name: string } | undefined = importEntry?.get('originalFileMetadata');
  if (!metadata) return;
  const filename = metadata.name;
  for (const part of filename.split(/_|(\s\-\s)/)) {
    const prefixed = extractPrefixed(episodePhrases, part, true);
    const m = prefixed?.match(/^(\d+)/);
    if (m) return processIdentifier(m[1]);
  }
  return filename.match(/(?<episode>([A-Z]?\d*)|(\d+))[\.\/]\d+/)?.groups?.episode;
}

export default function ScriptImporter({ content, importButton, importEntry }: ImporterComponentProps) {
  const { ph } = React.useContext(LanguageContext);
  const [script, setScript] = React.useState(processScript(content));
  const [selectedSeason, setSelectedSeason] = React.useState<DocumentSnapshot>();
  React.useEffect(() => setScript(processScript(content)), [content]);
  const { episodeId, scenes } = script;
  const episodeIdFromFilename = episodeId ? undefined : parseEpisodeIdFromFilename(importEntry);
  return (
    <View style={{ gap: 8, marginTop: 16, marginBottom: 16 }}>
      <Text variant="headlineMedium">
        {ph('episode') + ' ' + (episodeId || episodeIdFromFilename)}
      </Text>
      {scenes.map(({ id, content }) =>
        <Revealer key={id}
          title={<Text variant="titleMedium">{ph('scene') + ' ' + id}</Text>}
        >
          <View>
            {content.map((paragraph, index) =>
              <ScriptParagraph key={index} content={paragraph}
                web={Platform.OS === 'web'} Text={RNText} View={View}
              />
            )}
          </View>
        </Revealer>)}
      {scenes && importButton &&
        <View style={{ gap: 8, alignSelf: "center" }}>
          <SeasonSelector
            selectedSeason={selectedSeason} setSelectedSeason={setSelectedSeason} />
          {selectedSeason && importButton({
            seasonId: selectedSeason.id,
            ...(episodeIdFromFilename ? { episodeId: episodeIdFromFilename } : {})
          })}
        </View>}
    </View>
  );
}
