import { DocumentReference, QuerySnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { sortScenes, toScene } from "../common/scene";
import { Platform, ScrollView } from "react-native";
import { BreakdownTable } from "./BreakdownTable";
import { ProgressBar } from "react-native-paper";
import { ProjectContext } from "../data/projectContext";
import { useTeams } from "../data/team";
import { useScenesTableSectionForEpisode } from "./ScenesTable";

export function BreakdownView({ episodeRef }: {
  episodeRef: DocumentReference | undefined;
}) {
  const teams = useTeams();
  const sections = useScenesTableSectionForEpisode(episodeRef, teams);
  if (!sections) return <ProgressBar indeterminate />;
  return <BreakdownTable sections={sections} />;
}
